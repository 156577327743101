import { SCHEMA_COLUMN_TYPE_KEYS } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { ChangeCaseEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';

export const titleCaseList = [
  { value: ChangeCaseEnums.UPPER_CASE, label: 'UPPER CASE' },
  { value: ChangeCaseEnums.LOWER_CASE, label: 'lower case' },
  { value: ChangeCaseEnums.CAMEL_CASE, label: 'camelCase' },
  { value: ChangeCaseEnums.CAPITAL_CASE, label: 'Capital Case' },
  { value: ChangeCaseEnums.CONSTANT_CASE, label: 'CONSTANT_CASE' },
  { value: ChangeCaseEnums.DOT_CASE, label: 'dot.case' },
  { value: ChangeCaseEnums.HEADER_CASE, label: 'HEADER-CASE' },
  { value: ChangeCaseEnums.PARAM_CASE, label: 'param-case' },
  { value: ChangeCaseEnums.PATH_CASE, label: 'path/case' },
  { value: ChangeCaseEnums.PASCAL_CASE, label: 'Pascal Case' },
  { value: ChangeCaseEnums.SNAKE_CASE, label: 'snake_case' },
  { value: ChangeCaseEnums.SENTENCE_CASE, label: 'Sentence case' },
  { value: ChangeCaseEnums.NO_CASE, label: 'no case' },
  {
    value: ChangeCaseEnums.CAMEL_UPPER_CASE,
    label: 'Camel UPPER CASE (Apply 2 cases)',
  },
  {
    value: ChangeCaseEnums.CAMEL_LOWER_CASE,
    label: 'Camel lower case (Apply 2 cases)',
  },
];

const formFields = [
  {
    label: 'ID',
    property: 'id',
    isRequired: false,
    isHidden: true,
    value: undefined,
  },
  {
    label: 'Name',
    property: 'name',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input name',
    value: undefined,
  },
  {
    label: 'Schema type',
    property: 'schemaTypeId',
    type: 'ENUM',
    isRequired: false,
    message: 'Select type',
    isHidden: false,
    value: undefined,
    options: [],
    isDisabled: false,
  },
  {
    label: 'Label',
    property: 'label',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input label',
    value: undefined,
  },
  {
    label: 'Description',
    property: 'description',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input description',
    value: undefined,
  },
  {
    label: 'Type',
    property: 'type',
    type: 'ENUM',
    isRequired: true,
    message: 'Select property type',
    value: undefined,
    allowSearch: true,
    options: SCHEMA_COLUMN_TYPE_KEYS.map((type) => ({ label: type, value: type })),
  },
  {
    label: 'Default value',
    property: 'defaultValue',
    type: 'TEXT',
    isRequired: false,
    message: '',
    value: undefined,
  },
];

export const getFormFields = (
  schema: SchemaEntity,
  record?: any,
  currentSchemaTypeId?: string | null,
) => {
  const hasSchemaTypes = schema.types?.length > 1;

  const filteredFields = formFields.filter((field) => {
    // Hide schemaTypeId field if there are no schema types
    if (field.property === 'schemaTypeId') return hasSchemaTypes;

    // All other fields are included
    return true;
  });

  const fieldsWithValues = filteredFields.map((field) => {
    // Add value to field
    const newField: any = { ...field, value: record?.[field.property] };

    // Add initialValue to ENUM fields
    if (field.type === 'ENUM' && record?.[field.property]) {
      newField.initialValue = record?.[field.property];
    }

    // Add schema type options to schemaTypeId field
    if (hasSchemaTypes && field.property === 'schemaTypeId') {
      newField.options = schema.types.map((type) => ({ label: type.name, value: type.id }));
      newField.value = record ? record.schemaTypeId : currentSchemaTypeId;
      newField.initialValue = record ? record.schemaTypeId : currentSchemaTypeId;
    }

    // Add default value to type field
    if (field.property === 'type' && !record?.[field.property]) {
      newField.value = 'TEXT';
    }

    return newField;
  });

  return fieldsWithValues;
};
