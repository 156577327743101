import { Button, Card, Tooltip } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import CoreForm from '@legacy/core/records/components/Forms/CoreForm';
import { getSchemaActionVersion } from '@legacy/core/records/components/Forms/helpers';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import { getRecordByIdRequest, IGetRecordById } from '@legacy/core/records/store/actions';
import {
  CREATE_DB_RECORD_REQUEST,
  UPDATE_DB_RECORD_BY_ID_REQUEST,
} from '@legacy/core/records/store/constants';
import {
  getSchemaActionsList,
  getSchemaByModuleAndEntityRequest,
  ISchemaActionsList,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { Col, Row, Skeleton } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { hasPermissions } from '../../../helpers/rbacRules';
import { getSchemaFromShortListByModuleAndEntity } from '../../../helpers/schemaHelpers';
import { getSchemaActionsForPageHeader } from '../schemaActionFilters';
import SchemaUserActionFlow from '../SchemaUserActionFlow';

interface Props {
  colSpan?: number;
  colStyle?: any;
  entityName: string;
  getActions: (payload: ISchemaActionsList, cb: any) => void;
  getRecordById: (payload: IGetRecordById, cb?: any) => void;
  getSchema: (payload: ISchemaByModuleAndEntity, cb?: any) => void;
  initializeForm: (params: any) => void;
  launcherType?: 'SECTION' | 'INLINE';
  moduleName: string;
  onSuccess?: (record: DbRecordEntityTransform) => void;
  record: DbRecordEntityTransform | undefined;
  rowGutter?: number;
  rowStyle?: any;
  schemaReducer: ISchemaReducer;
  sectionTitle?: string;
  userReducer: any;
}

const SchemaActionPageHeader: FC<Props> = (props: Props) => {
  const {
    colSpan,
    colStyle,
    entityName,
    getActions,
    getRecordById,
    getSchema,
    initializeForm,
    launcherType,
    moduleName,
    onSuccess,
    record,
    rowGutter,
    rowStyle,
    schemaReducer,
    sectionTitle,
    userReducer,
  } = props;
  const [schemaActions, setSchemaActions] = useState<SchemaActionEntity[]>([]);
  const [isLoadingActions, setIsLoadingActions] = useState<boolean>(false);
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);

  // 1. Get Schema
  useEffect(() => {
    if (!schema && moduleName && entityName) {
      setIsLoadingActions(true);
      const shortListSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleName,
        entityName,
      );
      if (shortListSchema) {
        setSchema(shortListSchema);
      } else {
        getSchema(
          {
            moduleName,
            entityName,
          },
          (res: SchemaEntity) => {
            if (res) {
              setSchema(res);
            }
          },
        );
      }
    }
  }, [record]);

  // 2. Get Actions
  useEffect(() => {
    if (schema && record) {
      getActions(
        {
          schemaId: schema.id,
        },
        (res: SchemaActionEntity[]) => {
          if (res) {
            const filteredActions = getSchemaActionsForPageHeader(res, record, schema);
            setSchemaActions(filteredActions);
          }
          setIsLoadingActions(false);
        },
      );
    }
  }, [schema, record]);

  const launchForm = (schemaActionId: string) => {
    if (schemaActionId && schema) {
      const schemaAction = schemaActions.find((action: any) => action.id === schemaActionId);
      const schemaType = schema?.types?.find((type: any) => type.id === schemaAction?.schemaTypeId);

      initializeForm({
        hideRecordTypeField: true,
        formUUID: schemaActionId,
        showFormModal: true,
        title: schemaAction?.name || 'Form',
        showInitializing: false,
        isBatchCreateReq: false,
        schema: schema,
        hideRecordFormFields: true,
        recordType: schemaType?.name || null,
        schemaActionId: schemaActionId,
        isCreateReq: schemaAction?.isCreate || false,
        isUpdateReq: schemaAction?.isUpdate || false,
        selected: schemaAction?.isUpdate && record ? record : undefined,
      });
    }
  };

  const handleFormSubmit = (params: { event: string; results: { id: string } }) => {
    if (schema) {
      // [27-Mar-2024]
      //
      // In sandbox, certain properties would not be updated immediately, like TeamName
      // or OwnerName. Waiting for 1 second before fetching the record again did the job.
      // This happened on the Work Order detail view, with header schema action.
      if (!onSuccess && params.event === UPDATE_DB_RECORD_BY_ID_REQUEST) {
        setTimeout(() => {
          getRecordById({ schema, recordId: params.results.id });
        }, 2000);
      }

      // [27-Jan-2025]
      //
      // Success callbacks for create / update record actions
      if (
        onSuccess &&
        [CREATE_DB_RECORD_REQUEST, UPDATE_DB_RECORD_BY_ID_REQUEST].includes(params.event)
      ) {
        getRecordById({ schema, recordId: params.results.id }, (res: DbRecordEntityTransform) => {
          onSuccess(res);
        });
      }
    }
  };

  const renderSchemaActions = () => {
    if (schemaActions?.length! > 0 && schema) {
      return schemaActions.map((schemaAction: SchemaActionEntity, i: number) => {
        const version = getSchemaActionVersion(schemaAction);
        const Definition = schemaAction.definition;

        const canUserAccessAction = () => {
          let permissions: string[] =
            schemaAction?.permissions?.map((perm: any) => perm.name) || [];

          if (permissions.length > 0) {
            return hasPermissions(userReducer, permissions);
          } else {
            return true;
          }
        };

        let actionLabel: string | undefined = '';
        const isStepFlow = schemaAction?.isStepFlow;

        if (version === 1 && Definition?.actionLabel) {
          actionLabel = Definition?.actionLabel;
        } else if (version === 2 && Definition?.settings?.actionLabel) {
          actionLabel = Definition?.settings?.actionLabel;
        } else {
          actionLabel = 'Unnamed Action';
        }

        if (isStepFlow && Definition) {
          return (
            <Col key={schemaAction?.id} span={colSpan || undefined} style={colStyle || {}}>
              <SchemaUserActionFlow record={record!} schemaActionId={schemaAction?.id} />
            </Col>
          );
        } else if (!isStepFlow && Definition) {
          return (
            <Col span={colSpan || undefined} style={colStyle || {}}>
              <Tooltip
                fill
                disabled={canUserAccessAction()}
                content="You don't have permissions to access this action."
              >
                <Button
                  key={i}
                  intent="primary"
                  outlined
                  disabled={!canUserAccessAction()}
                  onClick={() => {
                    launchForm(schemaAction.id);
                  }}
                  text={actionLabel}
                />
              </Tooltip>
              <CoreForm
                type="MODAL"
                formUUID={schemaAction.id}
                onCloseEvent={() => {}}
                onSubmitEvent={(params: { event: string; results: any }) =>
                  handleFormSubmit(params)
                }
              />
            </Col>
          );
        } else {
          return <></>;
        }
      });
    }
  };

  return (
    <Row gutter={rowGutter || 8} style={rowStyle || {}}>
      {/* INLINE */}
      {!isLoadingActions && launcherType === 'INLINE' && renderSchemaActions()}

      {/* SECTION */}
      {launcherType === 'SECTION' && schemaActions?.length! > 0 && (
        <Col span={24}>
          <Skeleton loading={isLoadingActions}>
            <Card title={sectionTitle || 'Schema Actions'}>{renderSchemaActions()}</Card>
          </Skeleton>
        </Col>
      )}
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  getActions: (payload: ISchemaActionsList, cb: any) => dispatch(getSchemaActionsList(payload, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getRecordById: (payload: IGetRecordById, cb?: any) => dispatch(getRecordByIdRequest(payload, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
});

export default connect(mapState, mapDispatch)(SchemaActionPageHeader);
