import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';

// Until modules get defined icons at schema level, we use this as a helper method.
export const OdinDefaultAppIcons: any = {
  AuditModule: 'history',
  OrderModule: 'shopping-cart',
  ProductModule: 'barcode',
  BillingModule: 'credit-card',
  FieldServiceModule: 'timeline-events',
  CrmModule: 'id-number',
  ProjectModule: 'gantt-chart',
  ServiceModule: 'cell-tower',
  SupportModule: 'headset',
  SchemaModule: 'PartitionOutlined',
  IdentityManagerModule: 'team',
  IdentityModule: 'team',
};

const MODULES_EXCLUDED_FROM_NAVIGATION = ['SchemaModule'];

export type TNavigationStructureModule = {
  moduleName: string;
  showInApps: boolean;
  icon: string;
  entities: TNavigationStructureEntity[];
};

export type TNavigationStructureEntity = {
  entityName: string;
  menuEntityName: string;
  isVisibleInGlobalNav: boolean;
  position: number;
  isOutcomeForm: boolean;
};

// Go through all schemas and construct the default menu structure by module -> entity relationship. Schemas
// have isVisibleInGlobalNav property which is used to determine if the entity should be shown in the global nav.
export default function constructDefaultMenuStructure(
  schemas: Array<SchemaEntity>,
): TNavigationStructureModule[] {
  const schemasMap: any = {};

  if (schemas) {
    schemas.forEach((schema: any) => {
      if (!schemasMap[schema.moduleName]) {
        // Module
        schemasMap[schema.moduleName] = {
          moduleName: schema.moduleName,
          showInApps: !MODULES_EXCLUDED_FROM_NAVIGATION.includes(schema.moduleName),
          icon: OdinDefaultAppIcons[schema.moduleName] || 'application',
          entities: [],
        };
      }

      // Module -> Entities
      schemasMap[schema.moduleName].entities.push({
        entityName: schema.entityName,
        menuEntityName: schema.menuLabel || schema.entityName,
        isVisibleInGlobalNav: schema.isVisibleInGlobalNav ? true : false,
        position: schema.position,
        isOutcomeForm: !!schema.types?.some(
          (type: SchemaTypeEntity) => type.name === 'OUTCOME_FORM',
        ),
      });
    });

    const schemaList: TNavigationStructureModule[] = Object.values(schemasMap);
    schemaList.sort((a: any, b: any) => (a.moduleName > b.moduleName ? 1 : -1));
    schemaList.forEach((schema: any) => {
      schema.entities.sort((a: any, b: any) => {
        if (a.position && b.position) {
          return a.position > b.position ? 1 : -1;
        } else if (a.position) {
          return -1;
        } else if (b.position) {
          return 1;
        } else {
          return a.entityName > b.entityName ? 1 : -1;
        }
      });
    });

    // Add Identity Module since this is not coming back via Schemas
    schemaList.push({
      moduleName: 'IdentityManagerModule',
      showInApps: false,
      icon: 'user',
      entities: [
        {
          entityName: '',
          menuEntityName: 'Identity Manager',
          isVisibleInGlobalNav: false,
          position: 0,
          isOutcomeForm: false,
        },
      ],
    });
    return schemaList;
  } else {
    return [];
  }
}

export const doesUserHaveRoutesInTheAppMenu = (
  navigationStructure: TNavigationStructureModule[],
) => {
  // If there are no entities in the navigation structure, return false. If some modules have some entities that are visible in the global nav, return true.
  return (
    navigationStructure && navigationStructure.filter((module: any) => module.showInApps).length > 0
  );
};
