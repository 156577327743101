import { Button, InputGroup, Section } from '@blueprintjs/core';
import { Cell, Column, RenderMode, SelectionModes, Table2 } from '@blueprintjs/table';
import { Col, Row, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

import { BlueprintNavigation } from '@core/components/BlueprintPagination';
import { searchString } from '@core/helpers/searchHelpers';
import { httpGet } from '@core/http/requests';
import ColumnDetails from '@core/modules/ControlPanelModule/containers/ColumnsListView/ColumnDetails';
import SchemaActionEntityNameSelect from '@core/modules/ControlPanelModule/containers/FormsListView/SchemaActionEntityNameSelect';
import { getErrorMessage } from '@core/modules/ControlPanelModule/utils/errors';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { PageHeader } from '@legacy/components/PageHeader';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { useLocation } from 'react-router-dom';

interface Props {
  initializeForm: any;
  alertMessage: (params: { body: string; type: string }) => void;
  onSchemaSelected?: (schemaId: string) => void;
  schemaReducer: ISchemaReducer;
}

interface ITableData {
  key: string;
  name: string;
  description: string;
  entityName: string;
  moduleName: string;
}

const ColumnsListViewTable: FC<Props> = (props: Props) => {
  const { alertMessage, schemaReducer } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [selectedTableRegions, setSelectedTableRegions] = useState<any[]>([]);
  const [columnList, setColumnList] = useState<SchemaColumnEntity[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<SchemaColumnEntity | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(33);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [allEntityNames, setAllEntityNames] = useState<string[]>([]);
  const [selectedEntityName, setSelectedEntityName] = useState<string>('');

  let location = useLocation();

  const tableRef = React.createRef<any>();

  useEffect(() => {
    if (schemaReducer.list?.length! > 0) {
      getAllColumnsList();
    }
  }, [schemaReducer.list]);

  useEffect(() => {
    if (columnList.length > 0 && schemaReducer.list?.length! > 0) {
      getAllEntityNames();
    }
  }, [columnList, schemaReducer.list]);

  useEffect(() => {
    setSelectedTableRegions([]);
    setSelectedColumn(undefined);
  }, [currentPage]);

  // Get selected schemaAction details
  useEffect(() => {
    if (selectedTableRegions.length > 0 && tableSlice.length > 0) {
      const columnId: string | undefined =
        tableSlice[selectedTableRegions[0].rows[0]]?.key || undefined;
      const column: SchemaColumnEntity | undefined = columnList.find((c) => c.id === columnId);

      if (column) {
        setSelectedColumn(column);
      }
    }
  }, [selectedTableRegions, tableSlice, selectedEntityName]);

  const getAllEntityNames = () => {
    // Get unique entityNames from all schemas in the schemaReducer.shortList
    const entityNames: string[] = [];
    schemaReducer.list.forEach((schema: any) => {
      if (!entityNames.includes(schema.entityName)) {
        entityNames.push(schema.entityName);
      }
    });

    setAllEntityNames(entityNames);
  };

  const onSelect = (e: any) => {
    setSelectedTableRegions([
      {
        cols: [0, 3],
        rows: [e[0].rows[0], e[0].rows[0]],
      },
    ]);
  };

  useEffect(() => {
    if (columnList.length > 0) {
      let tableData: ITableData[];

      // Get a list of all schemas with entity name and id
      const schemaList = schemaReducer.list.map((s: any) => ({
        id: s.id,
        entityName: s.entityName,
        moduleName: s.moduleName,
        types: s.types || [],
      }));

      tableData = columnList.map((sa: any) => {
        const schema: any = schemaList.find((s: any) => s.id === sa.schemaId);
        const typeName: string =
          schema?.types?.find((t: any) => t.id === sa.schemaTypeId)?.name || '';

        return {
          key: sa.id,
          name: sa.name,
          description: sa.description,
          entityName: schema?.entityName || '',
          moduleName: schema?.moduleName || '',
        };
      });

      // Apply filtering by selected entity name
      if (selectedEntityName) {
        tableData = tableData.filter((t: ITableData) => {
          return t.entityName === selectedEntityName;
        });
      }

      // Apply search
      tableData = tableData.filter((t: ITableData) => {
        return searchString(t.name, searchKey);
      });

      //  Sort table daya by entityName
      tableData = tableData.sort((a, b) => a.name.localeCompare(b.name));

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey && !selectedEntityName) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [columnList, currentPage, searchKey, pageSize, selectedEntityName]);

  const getAllColumnsList = async () => {
    try {
      const res = await httpGet(`SchemaModule/v1.0/schemas-columns`);
      const columns: any[] = res.data.data || [];
      // console.log('🛠️ debug: All Columns', columns);
      setColumnList(columns);
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not retrieve schema actions. ' + message, type: 'error' });
      setColumnList([]);
    }
  };

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };

  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedColumn, tableRef, tableSlice]);

  const onSearch = (e: any) => {
    setSelectedTableRegions([]);
    setSearchKey(e.target.value);
  };

  const renderAreas = () => {
    return (
      <>
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={6}>
              <h2 style={{ margin: 0 }}>Column List</h2>
            </Col>
            <Col span={18} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search Columns"
                  intent={searchKey.length > 0 ? 'primary' : 'none'}
                  onChange={onSearch}
                  value={searchKey}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <SchemaActionEntityNameSelect
                  allEntityNames={allEntityNames}
                  selectedEntityName={selectedEntityName}
                  setSelectedEntityName={setSelectedEntityName}
                />
              </Space>
            </Col>
          </Row>
        </PageHeader>

        {/* Row with dynamically calculated height */}
        <Row className="listViewContainer">
          <Col span={selectedColumn ? 17 : 24} style={{ height: '100%', width: 0, padding: 1 }}>
            {/* Table */}
            <Table2
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              onSelection={onSelect}
              selectedRegions={selectedTableRegions}
              enableMultipleSelection={false}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              cellRendererDependencies={[tableWidth, currentPage, tableSlice]}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              columnWidths={[
                getColumnWidthByPercentage(30),
                getColumnWidthByPercentage(35),
                getColumnWidthByPercentage(20),
                getColumnWidthByPercentage(14.5),
              ]}
            >
              <Column
                key="name"
                name="Column Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].name}</Cell>
                )}
              />
              <Column
                key="description"
                name="Description"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].description}</Cell>
                )}
              />
              <Column
                key="entityName"
                name="Entity Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].entityName}</Cell>
                )}
              />
              <Column
                key="moduleName"
                name="Module Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].moduleName}</Cell>
                )}
              />
            </Table2>
          </Col>

          {/* Schema action Details */}
          {selectedColumn && (
            <Col className="listViewDetailsColumn" span={7}>
              <Section
                compact
                title="Column Details"
                rightElement={
                  <Button
                    icon="cross"
                    small
                    minimal
                    onClick={() => {
                      setSelectedTableRegions([]);
                      setSelectedColumn(undefined);
                    }}
                  />
                }
              >
                <ColumnDetails setSelectedColumn={setSelectedColumn} column={selectedColumn} />
              </Section>
            </Col>
          )}
        </Row>

        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={columnList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0 || !!selectedEntityName}
            />
          </div>
        </Row>
      </>
    );
  };

  return <div style={{ background: 'white', padding: '0 15px' }}>{renderAreas()}</div>;
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ColumnsListViewTable);
