import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Classes, Drawer, Spinner } from '@blueprintjs/core';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import {
  SCHEMA_COLUMN_TYPE_KEYS,
  SchemaColumnTypes,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { SchemaColumnValidatorTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/validator/schema.column.validator.types';

import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import { SharedFormReducer } from '@legacy/components/SharedForm/store/reducer';
import { errorNotification } from '@legacy/core/notifications/store/reducers';
import { getSchemaByIdRequest } from '@legacy/core/schemas/store/actions';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import {
  createSchemaColumnPermissionsRequest,
  deleteSchemaColumnPermissionsRequest,
  GetSchemaColumnById,
  getSchemaColumnByIdRequest,
  removeSchemaColumnOption,
  removeSchemaColumnSelected,
  setSchemaColumnSelected,
  UpdateSchemaColumn,
  updateSchemaColumnProperties,
  updateSchemaColumnRequest,
} from '@legacy/core/schemasColumns/store/actions';
import { SchemaColumnReducer } from '@legacy/core/schemasColumns/store/reducer';

import { titleCaseList } from '../formFields';

type ComponentProps = {
  schemaId: string;
  schemaColumnId: string;
  onClose: () => void;
  previewMode?: boolean;
};

type PropsType = ComponentProps & {
  disablePermissions: any;
  enablePermissions: any;
  formReducer: SharedFormReducer;
  getColumn: (params: GetSchemaColumnById, cb: any) => void;
  getSchemaById: (payload: any, cb?: any) => void;
  initializeForm: any;
  onUpdate?: (column: SchemaColumnEntity) => void;
  notifyError: any;
  removeOption: (params: any) => void;
  removeSchemaColumnSelected: () => void;
  schemaColumnId: string;
  schemaColumnReducer: SchemaColumnReducer;
  schemaId: string;
  schemaReducer: ISchemaReducer;
  setSchemaColumnSelected: (params: { column: SchemaColumnEntity }) => void;
  updateColumn: (params: UpdateSchemaColumn, cb?: any) => void;
  updateProperties: any;
};

interface State {
  validatorsList: string[];
  deleteColumnModalIsVisible: boolean;
  isUpdating: boolean;
  isDrawerInWideMode: boolean;
}

const { Option } = Select;

class SchemaColumnUpdateDrawer extends React.Component<PropsType, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: PropsType) {
    super(props);

    this.state = {
      validatorsList: [],
      deleteColumnModalIsVisible: false,
      isUpdating: false,
      isDrawerInWideMode: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<PropsType>, prevState: Readonly<State>) {
    if (prevProps.schemaColumnId !== this.props.schemaColumnId) {
      if (!this.props.schemaColumnId) {
        this.props.removeSchemaColumnSelected();
      } else {
        this.fetchData();
      }
    }

    if (
      prevProps.schemaColumnReducer.selected !== this.props.schemaColumnReducer.selected &&
      this.formRef.current
    ) {
      const { selected } = this.props.schemaColumnReducer;
      this.formRef.current?.setFieldsValue({
        id: selected?.id,
        name: selected?.name,
        type: selected?.type,
        schemaTypeId: selected?.schemaTypeId,
        // ODN-2224 linked schema association for the LOOKUP column
        linkedSchemaAssociationId: selected?.linkedSchemaAssociationId,
        // ODN-2340 linked schema types constraint fot the LOOKUP column
        linkedSchemaTypesConstraintId: selected?.linkedSchemaTypesConstraintId,
        description: selected?.description,
        isStatic: selected?.isStatic,
        label: selected?.label,
        mapping: selected?.mapping,
        placeholder: selected?.placeholder,
        isHidden: selected?.isHidden,
        defaultValue: selected?.defaultValue,
        format: selected?.format || '',
        isVisibleInTables: selected?.isVisibleInTables,
        isDisabled: selected?.isDisabled,
        isTitleColumn: selected?.isTitleColumn,
        isStatusColumn: selected?.isStatusColumn,
        isBulkUpdatable: selected?.isBulkUpdatable, // ODN-1792
        isTrackedColumn: (selected as any)?.isTrackedColumn || false,
        pii: (selected as any)?.pii || false,
        position: selected?.position,
        columnPosition: selected?.columnPosition,
        validators: selected?.validators?.map((elem: any) => elem.type),
        valueCase: selected?.valueCase,
        options: selected?.options?.map((elem: any) => ({
          id: elem.id,
          position: elem.position,
          label: elem.label,
          value: elem.value,
          description: elem.description,
        })),
      });
    }

    //  When column type is updated in schemaColumnReducer, update the validatorsList as well
    if (
      prevProps.schemaColumnReducer.selected?.type !== this.props.schemaColumnReducer.selected?.type
    ) {
      this.renderValidatorOptions(this.props.schemaColumnReducer.selected?.type);
    }
  }

  fetchData() {
    const {
      getColumn,
      schemaReducer,
      getSchemaById,
      schemaId,
      schemaColumnId,
      setSchemaColumnSelected,
    } = this.props;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);

    getColumn({ schemaId, schemaColumnId }, (res: any) => {
      setSchemaColumnSelected({ column: res });
    });

    if (!schema && schemaId) {
      getSchemaById({ schemaId: schemaId });
    }
  }

  renderValidatorOptions = (type: any): void => {
    let validatorsList: string[] = [];

    if (type !== undefined) {
      let options: any = [];
      Object.keys(SchemaColumnValidatorTypes).forEach((key) => {
        if (!!SchemaColumnValidatorTypes[key].columnTypes.find((t) => t === type)) {
          const option: any = SchemaColumnValidatorTypes[key].name;
          options = [...options, option];
        }
      });
      validatorsList = options;
    }

    this.setState({ validatorsList });
  };

  resetTable = () => {
    this.formRef.current?.resetFields();
  };

  async handleFormSubmit(params: { event: 'UPDATE' }) {
    const { notifyError, updateColumn, schemaId, schemaColumnId, onUpdate } = this.props;

    try {
      if (!!this.formRef.current) {
        await this.formRef.current.validateFields();
        const formErrors = this.formRef.current ? this.formRef.current.getFieldsError() : [];
        const hasErrors = formErrors.filter(({ errors }) => errors.length).length > 0;

        if (hasErrors) {
          return notifyError({
            message: 'form has errors, fix them and resubmit',
            validation: null,
            data: null,
          });
        } else {
          const values = this.formRef.current.getFieldsValue();

          this.setState({ isUpdating: true });

          // Once Column is updated, fetch the schema back so we can update the shortlist.
          updateColumn({ schemaId, schemaColumnId, body: values }, (res: any) => {
            this.closeModal();
            this.setState({ isUpdating: false });
            onUpdate && onUpdate(res);
          });

          setTimeout(() => {
            this.resetTable();
          }, 1000);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleValidatorChange = (value: any) => {
    const { updateProperties } = this.props;

    const fieldValues = this.formRef.current?.getFieldsValue();

    updateProperties({
      validators: fieldValues.validators.map((elem: any) => ({ type: elem })),
    });
  };

  renderOptionsList = () => {
    return (
      <>
        <h3>Enum Options</h3>
        {this.renderOptionsFields()}
      </>
    );
  };

  addEnumOption() {
    const { updateProperties, schemaColumnReducer } = this.props;
    const { selected } = schemaColumnReducer;

    const existingOption = selected?.options?.map((elem: any) => ({
      id: elem.id,
      position: elem.position,
      label: elem.label,
      value: elem.value,
      description: elem.description,
    }));

    const newOption = {
      value: `OPTION_${existingOption ? existingOption.length + 1 : 1}`,
      label: '',
      description: null,
      id: undefined,
      position: existingOption ? existingOption.length + 1 : 1,
    };

    if (existingOption && existingOption.length > 0) {
      updateProperties({
        options: [...existingOption, newOption],
      });
    } else {
      updateProperties({
        options: [newOption],
      });
    }
  }

  removeEnumOption = (index: number) => {
    const { removeOption } = this.props;
    const fieldValues = this.formRef.current?.getFieldsValue();
    const itemToRemove = fieldValues.options[index];

    removeOption({ value: itemToRemove.value });
  };

  handleEnumOptionChange(e: any, index: number) {
    const { updateProperties } = this.props;
    const fieldValues = this.formRef.current?.getFieldsValue();

    updateProperties({
      options: fieldValues.options,
    });
  }

  enablePermissionsSchemaColumn = () => {
    const { enablePermissions, updateProperties, schemaId, schemaColumnId } = this.props;
    enablePermissions({ schemaId, schemaColumnId }, (result: any) => {
      updateProperties({
        permissions: result,
      });
    });
  };

  disablePermissionsSchemaColumn = () => {
    const { disablePermissions, updateProperties, schemaId, schemaColumnId } = this.props;
    disablePermissions({ schemaId, schemaColumnId });
    updateProperties({
      permissions: [],
    });
  };

  // ODN-2224 linked schema association for the LOOKUP column
  renderLinkedSchemaAssociationSelect() {
    const { schemaId, schemaReducer, updateProperties } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);

    return (
      <Form.Item
        className="form-item"
        label="Linked Schema Association"
        name="linkedSchemaAssociationId"
        key="LinkedSchemaAssociationSelectFormItem"
        rules={[
          {
            required: true,
            message: 'Please select',
          },
        ]}
      >
        <Select
          key="LinkedSchemaAssociationSelect"
          placeholder="Select linked Schema Association"
          onChange={(e) => {
            updateProperties({ linkedSchemaAssociationId: e ?? null });
          }}
        >
          {schema?.associations.map((association: SchemaAssociationEntity) => (
            <Select.Option key={association.id} value={association.id}>
              {association.parentSchemaId !== schema.id ? (
                <div>
                  <span style={{ fontWeight: 600 }}>{association?.parentSchema?.entityName}</span> (
                  <span style={{ opacity: 0.8 }}>{association.label}</span>)
                </div>
              ) : (
                <div>
                  <span style={{ fontWeight: 600 }}>{association?.childSchema?.entityName}</span> (
                  <span style={{ opacity: 0.8 }}>{association.label}</span>)
                </div>
              )}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  // ODN-2224 linked schema types constraint for the LOOKUP column
  renderLinkedSchemaTypesConstraintSelect() {
    const { schemaId, schemaReducer, schemaColumnReducer, updateProperties } = this.props;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);

    const linkedSchemaAssociation = schema?.associations?.find(
      (a) => a.id === this.props.schemaColumnReducer.selected?.linkedSchemaAssociationId,
    );

    if (linkedSchemaAssociation && linkedSchemaAssociation?.schemaTypesConstraints?.length > 0) {
      return (
        <Form.Item
          className="form-item"
          label="Linked Schema Types Constraint"
          name="linkedSchemaTypesConstraintId"
          key={8}
          rules={[
            {
              required: true,
              message: 'Please select',
            },
          ]}
        >
          <Select
            placeholder="Select linked Schema Types Constraint"
            onChange={(e) => {
              updateProperties({ linkedSchemaTypesConstraintId: e ?? null });
            }}
          >
            {linkedSchemaAssociation?.schemaTypesConstraints?.map((constraint) => (
              <Select.Option key={constraint.id} value={constraint.id}>
                {constraint.parentSchemaType?.name ? constraint.parentSchemaType.name : 'ANY'}-
                {constraint.childSchemaType?.name ? constraint.childSchemaType.name : 'ANY'}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    }
  }

  renderSchemaColumnForm() {
    const { schemaReducer, schemaId, schemaColumnReducer, updateProperties, previewMode } =
      this.props;
    const { selected } = schemaColumnReducer;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);

    return (
      <Form
        layout={'vertical'}
        name="columnsForm"
        initialValues={this.formRef.current?.getFieldsValue()}
        ref={this.formRef}
        style={{ width: '100%', paddingBottom: 0 }}
      >
        <Row gutter={12}>
          <Col lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 2 }}>
            <Form.Item
              className="form-item"
              name="id"
              label="id"
              key={0}
              style={{ display: 'none' }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="form-item"
              name="name"
              label="Name"
              key={1}
              rules={[
                {
                  required: true,
                  message: 'Please input value',
                },
              ]}
            >
              <Input
                placeholder="external mapping name"
                onChange={(e) => updateProperties({ name: e.target.value })}
              />
            </Form.Item>
            <Form.Item className="form-item" name="mapping" label="Mapping" key={2} rules={[]}>
              <Input
                placeholder="external system mapping"
                onChange={(e) => updateProperties({ mapping: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Schema Type"
              name="schemaTypeId"
              key={3}
              rules={[]}
            >
              <Select
                placeholder="Select type"
                allowClear
                onChange={(e) => {
                  updateProperties({ schemaTypeId: e ?? null });
                }}
              >
                {schema?.types.map((type: any) => (
                  <Select.Option key={type.id} value={type.id}>
                    {type.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Description"
              name="description"
              key={4}
              rules={[
                {
                  required: true,
                  message: 'Please input value',
                },
              ]}
            >
              <Input
                placeholder="Description"
                onChange={(e) => updateProperties({ description: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Label"
              name="label"
              key={5}
              rules={[
                {
                  required: true,
                  message: 'Please input value',
                },
              ]}
            >
              <Input
                placeholder="Label"
                onChange={(e) => updateProperties({ label: e.target.value })}
              />
            </Form.Item>
            <Form.Item className="form-item" label="Placeholder" name="placeholder" key={6}>
              <Input
                placeholder="Placeholder"
                onChange={(e) => updateProperties({ placeholder: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Column Type"
              name="type"
              key={7}
              rules={[
                {
                  required: true,
                  message: 'Please select',
                },
              ]}
            >
              <Select
                placeholder="Select type"
                onChange={(e) => {
                  updateProperties({ type: e });
                  this.renderValidatorOptions(e);
                }}
              >
                {SCHEMA_COLUMN_TYPE_KEYS.map((option: any) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {selected?.type === 'ENUM' || selected?.type === 'ENUM_MULTI_SELECT'
              ? this.renderOptionsList()
              : ''}
            {selected?.type === SchemaColumnTypes.LOOKUP ||
            selected?.type === SchemaColumnTypes.FILE_MULTIPLE ||
            selected?.type === SchemaColumnTypes.FILE_SINGLE
              ? this.renderLinkedSchemaAssociationSelect()
              : ''}
            {selected?.type === SchemaColumnTypes.LOOKUP ||
            selected?.type === SchemaColumnTypes.FILE_MULTIPLE ||
            selected?.type === SchemaColumnTypes.FILE_SINGLE
              ? this.renderLinkedSchemaTypesConstraintSelect()
              : ''}
            <Form.Item
              className="form-item"
              label="Validators"
              name="validators"
              key={9}
              rules={[
                {
                  required: false,
                  message: 'Please select',
                },
              ]}
            >
              {
                <Select
                  mode="multiple"
                  placeholder="Select validators"
                  onChange={this.handleValidatorChange}
                >
                  {this.state.validatorsList.map((elem: any) => (
                    <Select.Option key={elem} value={elem}>
                      {elem}
                    </Select.Option>
                  ))}
                </Select>
              }
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Default value"
              name="defaultValue"
              key={10}
              rules={[
                {
                  message: 'Please input value',
                },
              ]}
            >
              <Input
                placeholder="some value"
                onChange={(e) => updateProperties({ defaultValue: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Default format"
              name="format"
              key={11}
              rules={[
                {
                  required: false,
                  message: 'Please input value',
                },
              ]}
            >
              <Input
                placeholder="Default format"
                onChange={(e) => updateProperties({ format: e.target.value })}
              />
            </Form.Item>
            <Form.Item className="form-item" label="Value Case" key={12} name="valueCase">
              <Select onChange={(value) => updateProperties({ valueCase: value })} allowClear>
                {titleCaseList.map((it: any) => (
                  <Option value={it.value}>{it.label}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Position"
              name="position"
              key={13}
              rules={[
                {
                  required: false,
                  message: 'Please input value',
                },
              ]}
            >
              <Input
                placeholder="Position"
                type="number"
                onChange={(e) => updateProperties({ position: parseInt(e.target.value) })}
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Column position"
              name="columnPosition"
              key={14}
              rules={[
                {
                  required: false,
                  message: 'Please input value',
                },
              ]}
            >
              <Select
                placeholder="Select column position"
                allowClear
                onChange={(e: number) => updateProperties({ columnPosition: e })}
              >
                <Option value={1} key={15}>
                  1
                </Option>
                <Option value={2} key={16}>
                  2
                </Option>
              </Select>
            </Form.Item>
            <div className="columns-section-wrapper">
              <Row>
                <Col span={8}>
                  <Form.Item className="form-item" name="isTitleColumn" key={17}>
                    <Checkbox
                      checked={selected?.isTitleColumn}
                      onChange={(e) => updateProperties({ isTitleColumn: e.target.checked })}
                    >
                      Is Title Column
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="form-item" name="isDisabled" key={18}>
                    <Checkbox
                      checked={selected?.isDisabled}
                      onChange={(e) => updateProperties({ isDisabled: e.target.checked })}
                    >
                      Is Disabled
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="form-item" name="pii" key={19}>
                    <Checkbox
                      checked={(selected as any)?.pii}
                      onChange={(e) => updateProperties({ pii: e.target.checked })}
                    >
                      Is Encrypted
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item className="form-item" name="isVisibleInTables" key={20}>
                    <Checkbox
                      checked={selected?.isVisibleInTables}
                      onChange={(e) =>
                        updateProperties({
                          isVisibleInTables: e.target.checked,
                        })
                      }
                    >
                      Is Visible in Tables
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="form-item" name="isSearchable" key={21}>
                    <Checkbox
                      checked={selected?.isSearchable}
                      onChange={(e) => updateProperties({ isSearchable: e.target.checked })}
                    >
                      Is Searchable
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="form-item" name="isHidden" key={199}>
                    <Checkbox
                      checked={(selected as any)?.isHidden}
                      onChange={(e) => updateProperties({ isHidden: e.target.checked })}
                    >
                      Is Hidden
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item className="form-item" name="isStatusColumn" key={22}>
                    <Checkbox
                      checked={selected?.isStatusColumn}
                      onChange={(e) => updateProperties({ isStatusColumn: e.target.checked })}
                    >
                      Is Status Column
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="form-item" name="isTrackedColumn" key={23}>
                    <Checkbox
                      checked={!!(selected as any)?.isTrackedColumn}
                      onChange={(e) => updateProperties({ isTrackedColumn: e.target.checked })}
                    >
                      Is Tracked Column
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    );
  }

  renderOptionsFields() {
    return (
      <Form.List name="options">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field: any, index) => (
                <Row key={field.key} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={6}>
                    <Form.Item
                      name={[field.name, 'value']}
                      fieldKey={[field.fieldKey, 'value']}
                      rules={[{ required: true }]}
                      label="Value"
                    >
                      <Input
                        placeholder="Value"
                        onChange={(e) => this.handleEnumOptionChange(e, index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[field.name, 'label']}
                      fieldKey={[field.fieldKey, 'label']}
                      rules={[{ required: true }]}
                      label="Label"
                    >
                      <Input
                        placeholder="Label"
                        onChange={(e) => this.handleEnumOptionChange(e, index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={[field.name, 'description']}
                      fieldKey={[field.fieldKey, 'description']}
                      rules={[{ required: false }]}
                      label="Description"
                    >
                      <Input
                        placeholder="Description"
                        onChange={(e) => this.handleEnumOptionChange(e, index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name={[field.name, 'position']}
                      fieldKey={[field.fieldKey, 'position']}
                      rules={[{ required: true }]}
                      label="Position"
                    >
                      <Input
                        placeholder="Position"
                        onChange={(e) => this.handleEnumOptionChange(e, index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1} style={{ marginTop: '37px', paddingLeft: '0' }}>
                    <DeleteOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        this.removeEnumOption(index);
                      }}
                    />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  onClick={() => {
                    this.addEnumOption();
                  }}
                  style={{ width: '100%' }}
                >
                  <PlusOutlined /> Add Option
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    );
  }

  closeModal = () => {
    const { removeSchemaColumnSelected, onClose } = this.props;
    this.setState({ isDrawerInWideMode: false });
    if (onClose) onClose();
    removeSchemaColumnSelected();
  };

  render() {
    const { schemaColumnReducer, schemaId, schemaColumnId, previewMode } = this.props;
    const { selected, isRequesting } = schemaColumnReducer;

    return (
      <Drawer
        icon={previewMode ? undefined : 'edit'}
        title={previewMode ? 'View Column Properties' : 'Edit Column Properties'}
        canEscapeKeyClose={!this.state.isUpdating}
        canOutsideClickClose={!this.state.isUpdating}
        style={{ width: isMobile ? '90%' : this.state.isDrawerInWideMode ? '70%' : '35%' }}
        isOpen={!!schemaId && !!schemaColumnId}
        onClose={this.closeModal}
      >
        {isRequesting ? (
          <div className="loading-page">
            <Spinner />
          </div>
        ) : (
          <>
            {/* Form */}
            <div className={Classes.DRAWER_BODY} style={{ marginTop: 1 }}>
              <Row style={{ padding: 20 }}>
                <Col span={24}>{selected ? this.renderSchemaColumnForm() : <></>}</Col>
              </Row>
            </div>

            {/* Form Control */}
            <Row className={Classes.DRAWER_FOOTER}>
              <Col span={4}>
                <Button
                  minimal
                  icon={this.state.isDrawerInWideMode ? 'drawer-left' : 'drawer-right'}
                  onClick={() =>
                    this.setState({ isDrawerInWideMode: !this.state.isDrawerInWideMode })
                  }
                />
              </Col>

              <Col span={20} style={{ textAlign: 'right' }}>
                <Button
                  text="Close"
                  onClick={this.closeModal}
                  style={{ marginRight: previewMode ? 0 : 8 }}
                  disabled={this.state.isUpdating}
                />
                {!previewMode && (
                  <Button
                    key="3"
                    intent="primary"
                    loading={this.state.isUpdating}
                    onClick={() => this.handleFormSubmit({ event: 'UPDATE' })}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          </>
        )}
      </Drawer>
    );
  }
}

const mapState = (state: any) => ({
  formReducer: state.formReducer,
  schemaReducer: state.schemaReducer,
  schemaColumnReducer: state.schemaColumnReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  updateProperties: (params: any) => dispatch(updateSchemaColumnProperties(params)),
  getColumn: (params: GetSchemaColumnById, cb: any) =>
    dispatch(getSchemaColumnByIdRequest(params, cb)),
  updateColumn: (params: UpdateSchemaColumn, cb: any) =>
    dispatch(updateSchemaColumnRequest(params, cb)),
  removeOption: (params: any) => dispatch(removeSchemaColumnOption(params)),
  enablePermissions: (params: any, cb: any) =>
    dispatch(createSchemaColumnPermissionsRequest(params, cb)),
  disablePermissions: (params: any, cb: any) =>
    dispatch(deleteSchemaColumnPermissionsRequest(params, cb)),
  notifyError: (params: any) => dispatch(errorNotification(params)),
  getSchemaById: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  setSchemaColumnSelected: (params: { column: SchemaColumnEntity }) =>
    dispatch(setSchemaColumnSelected(params)),
  removeSchemaColumnSelected: () => dispatch(removeSchemaColumnSelected()),
});

export default connect(mapState, mapDispatch)(SchemaColumnUpdateDrawer);
