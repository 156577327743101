import { Button, Callout, Drawer } from '@blueprintjs/core';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { ISearchRecords, searchRecordsRequest } from '@legacy/core/records/store/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
import { Col, Row, Typography } from 'antd';
import { FunctionComponent } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

interface OwnProps {
  supportDrawerVisible: boolean;
  toggleSupportModal: any;
  recordReducer: any;
  schemaReducer: any;
  searchRecords: any;
  userReducer: any;
  getSchema: Function;
}

type SchemaType = {
  id: string;
  name: string;
  label: string;
  isDefault: boolean;
};

type Props = OwnProps;

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const { Title } = Typography;

const SupportDrawer: FunctionComponent<Props> = (props) => {
  const { supportDrawerVisible, toggleSupportModal, getSchema } = props;

  return (
    <Drawer
      icon="headset"
      title="Support"
      style={{ width: isMobile ? '100%' : 450 }}
      onClose={() => toggleSupportModal()}
      isOpen={supportDrawerVisible}
    >
      <Row style={{ padding: 20 }}>
        {/* Report a Bug */}
        <Col span={24}>
          <Callout intent="primary" title="Report a Bug" icon="envelope">
            <Row>
              <Col span={24} style={{ marginTop: 7 }}>
                If you have encountered a bug or an issue while using the application, please report
                it to <a href="mailto:bugs@youfibre.com">bugs@youfibre.com</a>.
              </Col>
              <Col span={24} style={{ marginTop: 15 }}>
                <a href="mailto:bugs@youfibre.com">
                  <Button intent="primary" text="Report a Bug" />
                </a>
              </Col>
            </Row>
          </Callout>
        </Col>

        {/* Raise provisioning Issue */}
        <Col span={24} style={{ marginTop: 15 }}>
          <Callout intent="warning" title="Raise Provisioning issue" icon="issue">
            <Row>
              <Col span={24} style={{ marginTop: 7 }}>
                If you encounter a provisioning issue while using the application, click the button
                below to contact our support team.
              </Col>
              <Col span={24} style={{ marginTop: 15 }}>
                <Button
                  intent="warning"
                  text="Raise Issue"
                  disabled={!(window! as any)?.fcWidget}
                  onClick={() => {
                    (window! as any)?.fcWidget?.open();
                  }}
                />
              </Col>
            </Row>
          </Callout>
        </Col>
      </Row>
    </Drawer>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

export default connect(mapState, mapDispatch)(SupportDrawer);
