import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Button, InputGroup, Intent, Spinner, Tag, Tooltip } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { io } from 'socket.io-client';

import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

import { IOpenRecordDrawer } from '@legacy/core/userInterface/store/actions';
import { BlueprintNavigation } from '@core/components/BlueprintPagination';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import { getRequestAborter, httpPost } from '@core/http/requests';
import { toSentenceCase } from '@core/helpers/stringHelpers';
import { OdinElasticFilters } from '@core/components/OdinElasticFilters';
import OdinTable, { TSort } from '@core/components/OdinTable';

import CommunicationsStatusTag from '../../../MyCasesViewV2/components/CommunicationsStatusTag';
import { SupportDashboardContext } from '../../index';
import { getCaseContactFromRecord } from '../../helpers';
import AssignAgentToCaseDropdown from './AssignAgentToCaseDropdown';
import { useOdinSearchState } from './context/provider';
import { getHostName } from '@core/http/helpers';
import { OdinSearchParams } from './context/types';
import { connect } from 'react-redux';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const CASE = 'Case';

interface ITableDataItem {
  key: string;
  caseNumber: ReactNode;
  contact?: ReactNode;
  age: ReactNode;
  channel: string;
  category: ReactNode;
  Sla: ReactNode;
  agent: ReactNode;
  action: ReactNode;
}

interface IWebSocketEvent {
  caseId: string;
  type: 'CASE_UPDATED' | 'CASE_STAGE_UPDATED';
  data: DbRecordEntityTransform;
}

const SOCKET_URL = `${getHostName()}:4002/ChatWebsocketModule/TwilioChatWebsocket/v1.0`;

const getSortParams = (sort: TSort) => {
  switch (sort.field) {
    case 'age':
      return { createdAt: { order: sort.order } };
    case 'Sla':
      return { 'properties.SlaEndDate': { order: sort.order } };
    case 'status':
      return { 'stage.name.keyword': { order: sort.order } };
    default:
      return undefined;
  }
};

interface Props {
  alertMessage: (params: { body: string; type: string }) => void;
  openDrawer: (params: IOpenRecordDrawer) => void;
  userReducer: any;
}

const GLOBAL_EVENT_UUID = '39f5af096d0c44dbabe3225121438f9';

const CaseListView: React.FC<Props> = (props: Props) => {
  const { alertMessage, openDrawer, userReducer } = props;
  const { caseSchema } = useContext(SupportDashboardContext);
  const [socket, setSocket] = useState<any>(undefined);

  // Initialize WebSockets and event listeners
  useEffect(() => {
    const s = io(SOCKET_URL, {
      path: '/ws/socket.io',
      query: {
        clientId: userReducer.user?.id,
      },
    });
    if (s) {
      setSocket(s);
    }
  }, []);

  useEffect(() => {
    // Connect to WS, join global user room and listen for case events
    if (socket) {
      socket.on('connect', () =>
        console.log('%cdebug: [WS] [Cases List] Connected to WebSockets service', 'color:#bada55'),
      );
      socket?.emit('joinRoom', GLOBAL_EVENT_UUID);
      socket?.on(`case-event`, handleIncomingEvent);
    }

    // Unmount cleanup
    return () => {
      if (socket) {
        console.log('%cdebug: [WS] [Cases List] Disconnected from WebSockets service', 'color:red');
        socket?.emit('leaveRoom', GLOBAL_EVENT_UUID);
        socket?.off('case-event', handleIncomingEvent);
        socket?.disconnect();
      }
    };
  }, [socket]);

  // Here you will have events that occur when a case is updated, stage is updated etc.
  const handleIncomingEvent = (message: IWebSocketEvent) => {
    if (['CASE_UPDATED', 'CASE_STAGE_UPDATED'].includes(message.type) && message.data)
      // Update case list with latest case info if found.
      setCaseList((prevCaseList) => {
        const newCaseList = prevCaseList.map((item) => {
          if (item.id === message.data.id) {
            return message.data;
          } else {
            return item;
          }
        });
        return newCaseList;
      });
  };

  // Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);
  const [caseList, setCaseList] = useState<DbRecordEntityTransform[]>([]);
  const [inputQuery, setInputQuery] = useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = useState<string>(inputQuery);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Search Params Context
  const { isLoading: isLoadingParams, selectedParams } = useOdinSearchState();

  // Initialize timer to refetch ES data
  useEffect(() => {
    let requestAborter: any = null;
    const interval = setInterval(() => {
      if (caseSchema && !document.hidden && !isLoadingParams) {
        getCaseList({ stringQuery: debouncedQuery, selectedParams, currentPage }, true).then(
          (aborter) => (requestAborter = aborter),
        );
      }
    }, 15000);
    return () => {
      clearInterval(interval);
      if (requestAborter) requestAborter.cancel('State updated');
    };
  }, [
    isLoadingParams,
    caseSchema,
    debouncedQuery,
    selectedParams?.filters,
    selectedParams?.sort,
    currentPage,
  ]);

  // Set a timeout to update the debounced query after 500ms
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(inputQuery);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [inputQuery]);

  useEffect(() => {
    let requestAborter: any = null;

    if (caseSchema && !isLoadingParams) {
      getCaseList({ stringQuery: debouncedQuery, selectedParams, currentPage }).then(
        (aborter) => (requestAborter = aborter),
      );
    }

    return () => {
      if (requestAborter) requestAborter.cancel('State updated');
    };
  }, [
    isLoadingParams,
    currentPage,
    caseSchema,
    debouncedQuery,
    selectedParams?.filters,
    selectedParams?.sort,
    currentPage,
  ]);

  const getCaseList = async (
    params: {
      stringQuery: string;
      selectedParams?: OdinSearchParams;
      currentPage?: number;
    },
    background = false,
  ) => {
    const { stringQuery, selectedParams } = params;
    const requestAborter = getRequestAborter();
    try {
      if (caseSchema) {
        if (!background) {
          setIsRefreshing(true);
          setIsLoading(true);
        }
        // Search 2.0
        let query = {
          returnQueryPlan: false,
          query: {
            entity: 'SupportModule:Case',
            type: 'and',
            value: stringQuery
              ? [
                {
                  columnName: 'RecordNumber',
                  operator: 'anyTerm',
                  value: stringQuery,
                },
                ...(selectedParams?.filters ? selectedParams.filters : []),
              ]
              : selectedParams?.filters
                ? selectedParams.filters
                : [],
            returnProperties: [
              'id',
              'title',
              'recordNumber',
              'createdAt',
              'stage',
              'entity',
              'caseTeam',
              'caseOwner',
              'caseContact',
              'properties.SlaEndDate',
              'properties.OwnerId',
              'properties.TeamId',
              'properties.ContactId',
              'properties.Category',
              'properties.Source',
              'properties.Channel',
            ],
            sort: selectedParams?.sort
              ? getSortParams(selectedParams.sort)
              : { createdAt: { order: 'desc' } },
            pageSize: pageSize,
            pageNumber: currentPage - 1,
          },
        };

        httpPost(`SupportModule/v2.0/records/search`, query, undefined, requestAborter.token).then(
          (res) => {
            const records = res?.data?.data?.records || [];
            // console.log('%cdebug: Search Results', 'color:limegreen', res?.data);
            const totalRecords = res?.data?.data?.totalRecords || 0;
            setTotalRecordsCount(totalRecords);
            setIsRefreshing(false);
            setIsLoading(false);
            setCaseList(records);
          },
        );
      }
    } catch (e: any) {
      setIsRefreshing(false);
      setIsLoading(false);
      alertMessage({
        body: 'Failed to fetch case list.',
        type: 'error',
      });
    }
    return requestAborter;
  };

  const updateRecordInCaseList = (record: DbRecordEntityTransform) => {
    const updatedCaseList = caseList.map((item: DbRecordEntityTransform) => {
      if (item.id === record.id) {
        return record;
      } else {
        return item;
      }
    });
    setCaseList(updatedCaseList);
  };

  const renderSLATag = (item: DbRecordEntityTransform) => {
    const slaEndDate = getProperty(item, 'SlaEndDate');
    if (!slaEndDate) return null;

    const remainingTime = dayjs(slaEndDate).diff(dayjs(), 'second');
    let intent: Intent = Intent.SUCCESS;

    // If remaining time is less than 2 minutes, show as danger
    if (remainingTime < 10 * 60) intent = Intent.WARNING;
    else if (remainingTime < 2 * 60) intent = Intent.DANGER;

    return (
      <Tooltip
        hoverOpenDelay={1000}
        position="top"
        content={String(dayjs(getProperty(item, 'SlaEndDate')).format('DD/MM/YYYY HH:mm:ss'))}
      >
        <Tag
          style={{ cursor: 'pointer', minWidth: '100%', borderRadius: 5 }}
          key={`age${item.id}`}
          minimal
          fill
          large
          intent={intent}
        >
          {dayjs().to(dayjs(getProperty(item, 'SlaEndDate')))}
        </Tag>
      </Tooltip>
    );
  };

  const tableData: ITableDataItem[] = caseList.map((item: DbRecordEntityTransform) => {
    return {
      key: item.id,
      caseNumber: (
        <Link target="_blank" key={item.id} to={getBrowserPath(item)}>
          {item.recordNumber}
        </Link>
      ),
      contact: (
        <Row>
          <Col span={20}>{getCaseContactFromRecord(item)}</Col>
          <Col span={4}>
            {item.caseContact?.length! > 0 ? (
              <Link to={`/CrmModule/Contact/${item.caseContact[0].id}`} target="_blank">
                <Button
                  small
                  intent="primary"
                  minimal
                  icon={<i className="bi bi-box-arrow-up-right" />}
                />
              </Link>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      ),
      age: (
        <Tooltip
          hoverOpenDelay={1000}
          position="top"
          content={String(dayjs(item.createdAt).format('DD/MM/YYYY HH:mm:ss'))}
        >
          <span style={{ cursor: 'pointer' }} key={`age${item.id}`}>
            {dayjs(item.createdAt).fromNow()}
          </span>
        </Tooltip>
      ),
      channel: `${
        getProperty(item, 'Channel') ? toSentenceCase(getProperty(item, 'Channel')) : 'Unknown'
      }`,
      category: getProperty(item, 'Category') || '',
      Sla: renderSLATag(item),
      agent: (
        <Row align="middle" key={`assignCaseRow1${item.id}`} style={{ marginLeft: 15 }}>
          <Col span={24} key={`assignCaseCol1${item.id}`}>
            <AssignAgentToCaseDropdown caseRecord={item} onRecordUpdate={updateRecordInCaseList} />
          </Col>
        </Row>
      ),
      status: (
        <Row align="middle" justify="center">
          <Col>
            <CommunicationsStatusTag status={item.stage?.name} />
          </Col>
        </Row>
      ),
      action: (
        <Row align="middle" justify="center">
          <Col>
            <Button
              key={item.id}
              intent="primary"
              minimal
              onClick={() => {
                openDrawer({
                  recordId: item.id,
                  moduleName: SUPPORT_MODULE,
                  entityName: CASE,
                });
              }}
              icon={<i className="bi bi-eye" />}
            />
          </Col>
        </Row>
      ),
    };
  });

  return (
    <>
      <Row>
        <Col span={20}>
          <h1 style={{ margin: 0 }}>Cases</h1>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <InputGroup
            // disabled={isLoading}
            type="search"
            placeholder="Search Case Number"
            onChange={(e: any) => setInputQuery(e.target.value)}
            intent={inputQuery.length > 0 ? 'primary' : 'none'}
            leftIcon={isRefreshing && inputQuery.length > 0 ? null : 'search'}
            leftElement={
              isRefreshing && inputQuery.length > 0 ? (
                <Spinner size={20} style={{ padding: 5 }} />
              ) : undefined
            }
          />
        </Col>
      </Row>
      {/* Table */}
      <div style={{ marginTop: 20, overflowX: 'auto' }}>
        <OdinElasticFilters schema={caseSchema} />
        <OdinTable
          isLoading={isLoading || isLoadingParams}
          isRefreshing={isRefreshing}
          height="calc(100vh - 248px)"
          data={tableData}
          columns={[
            {
              key: 'caseNumber',
              title: 'Case #',
              width: 2,
              // filterable: true,
            },
            {
              key: 'contact',
              title: 'Contact',
              width: 3,
              // searchable: true,
            },
            {
              key: 'age',
              title: 'Age',
              width: 3,
              sortable: true,
              sortOrder:
                selectedParams?.sort?.field === 'age'
                  ? (selectedParams.sort.order as TSort['order'])
                  : undefined,
            },
            {
              key: 'channel',
              title: 'Channel',
              width: 3,
              // filterable: true,
            },
            {
              key: 'category',
              title: 'Category',
              width: 3,
              ellipsize: true,
            },
            {
              key: 'Sla',
              title: 'Sla',
              width: 2,
              align: 'center',
              sortable: true,
              sortOrder:
                selectedParams?.sort?.field === 'Sla'
                  ? (selectedParams.sort.order as TSort['order'])
                  : undefined,
            },
            {
              key: 'agent',
              title: 'Agent',
              width: 4,
              // searchable: true,
              align: 'center',
              // hideDivider: true,
            },
            {
              key: 'status',
              title: 'Status',
              width: 3,
              align: 'center',
              sortable: true,
              sortOrder:
                selectedParams?.sort?.field === 'status'
                  ? (selectedParams.sort.order as TSort['order'])
                  : undefined,
            },
            {
              key: 'action',
              title: '',
              width: 1,
            },
          ]}
        />
      </div>

      {/* Pagination */}
      <Row style={{ background: 'white' }}>
        <div style={{ padding: '10px 0' }}>
          <BlueprintNavigation
            totalCount={totalRecordsCount}
            currentPage={currentPage}
            pageSize={pageSize}
            onPaginate={setCurrentPage}
            // disabled={caseList.length <= pageSize}
          />
        </div>
      </Row>
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(CaseListView);
