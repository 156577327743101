import { Button, Icon, Menu, MenuItem, Popover, Tag } from '@blueprintjs/core';
import { renderBooleanValue } from '@core/helpers/UIHelpers';
import { httpGet } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';

interface Props {
  userReducer: any;
  alertMessage: (params: { body: string; type: string }) => void;
}

const ProfileView: React.FC<Props> = (props: Props) => {
  const { userReducer, alertMessage } = props;

  const [user, setUser] = useState<any>(undefined);
  const [isLoadingUserInfo, setIisLoadingUserInfo] = useState<boolean>(false);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      setIisLoadingUserInfo(true);
      const id = userReducer.user?.id;
      const res = await httpGet(`IdentityModule/v2.0/users/${id}`);

      const info = res?.data?.data;
      if (info) {
        setUser(info);
      }

      setIisLoadingUserInfo(false);

      console.log('🛠️ debug: USER INFO', info);
    } catch (e: any) {
      console.error('❌ error: fetchUserInfo', e);
      setIisLoadingUserInfo(false);
    }
  };

  const copyTextToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    alertMessage({ body: 'Copied to clipboard', type: 'success' });
  };

  const PropertyRow = (props: { title: string; value: any | ReactElement }): ReactElement => {
    const { title, value } = props;

    return (
      <Row style={{ borderBottom: '1px solid #f2f2f2' }}>
        <Col span={4} style={{ padding: 10 }}>
          <span style={{ fontWeight: 500 }}>{title}</span>
        </Col>
        <Col span={12} style={{ padding: 10 }}>
          {value}
        </Col>
      </Row>
    );
  };

  const ActionsMenu = () => {
    return (
      <Menu>
        {/* Change Password */}
        <MenuItem
          icon="clipboard"
          text="Copy User ID"
          onClick={() => copyTextToClipboard(user.id)}
        />
        <MenuItem
          icon="envelope"
          text="Copy User Email"
          onClick={() => copyTextToClipboard(user.email)}
        />
      </Menu>
    );
  };

  const renderUserInfo = () => {
    return (
      <div style={{ padding: 15 }}>
        <Row justify="space-between">
          {/* Left Side */}
          <Col>
            <Row>
              {/* User avatar, name, email ... */}
              <Col>
                <div
                  style={{
                    display: 'inline-block',
                    background: '#EFEFEF',
                    padding: 20,
                    height: 94,
                    width: 94,
                    borderRadius: 10,
                  }}
                >
                  <Icon icon="person" size={49} style={{ opacity: 0.2 }} />
                </div>
              </Col>
              <Col style={{ paddingLeft: 15 }}>
                <Row>
                  <Col span={24}>
                    <h2 style={{ margin: 0 }}>
                      {user?.firstname} {user.lastname}
                    </h2>
                  </Col>
                  <Col span={24} style={{ marginTop: 10 }}>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </Col>
                  <Col span={24}>
                    <span>{user?.roleName || 'Role'}</span>
                  </Col>
                  <Col span={24}>
                    <span style={{ opacity: 0.8 }}>
                      {user?.organization?.name || 'Unknown Organization'}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {/* Actions */}
          <Col style={{ textAlign: 'right' }}>
            <Row>
              <Col span={24}>
                <Popover content={<ActionsMenu />} placement="bottom">
                  <Button rightIcon="caret-down" text="Actions" />
                </Popover>
              </Col>

              <Col span={24}>
                <span>&nbsp;</span>
              </Col>
              <Col span={24}>
                <span>&nbsp;</span>
              </Col>
              <Col span={24}>
                <span>Version: {import.meta.env.VITE_VERSION || '-'}</span>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ marginTop: 25 }} justify="center">
          <Col span={24} style={{ border: '1px solid #f2f2f2', borderRadius: 10 }}>
            <PropertyRow title="Company Name" value={user?.companyName} />
            <PropertyRow title="Department Name" value={user?.departmentName} />
            <PropertyRow title="Email" value={user?.email} />
            <PropertyRow title="Enable MFA" value={renderBooleanValue(String(user?.enableMfa))} />
            <PropertyRow title="Job Title" value={user?.jobTitle} />

            <PropertyRow title="Region Name" value={user?.regionName || '-'} />
            <PropertyRow title="Reports To Name" value={user?.reportsToName || '-'} />
            <PropertyRow title="Role Name" value={user?.roleName || '-'} />
            <PropertyRow
              title="Status"
              value={
                user?.status === 'ACTIVE' ? (
                  <Tag
                    key={user.status}
                    intent="success"
                    round
                    minimal
                    style={{ verticalAlign: 'middle' }}
                  >
                    ACTIVE
                  </Tag>
                ) : (
                  <Tag
                    key={user.status}
                    intent="warning"
                    round
                    minimal
                    style={{ verticalAlign: 'middle' }}
                  >
                    INACTIVE
                  </Tag>
                )
              }
            />
            <PropertyRow title="Team Name" value={user?.teamName || '-'} />
            <PropertyRow title="Territory Name" value={user?.territoryName || '-'} />
            <PropertyRow
              title="Updated At"
              value={user?.updatedAt ? dayjs(user.updatedAt).format('DD/MM/YYYY HH:mm:ss') : '-'}
            />
            <PropertyRow
              title="Created At"
              value={user?.createdAt ? dayjs(user.createdAt).format('DD/MM/YYYY HH:mm:ss') : '-'}
            />
          </Col>
        </Row>
      </div>
    );
  };

  return !isLoadingUserInfo && user ? renderUserInfo() : <></>;
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ProfileView);
