import { Button, Section } from '@blueprintjs/core';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { httpGet, httpPost } from '@core/http/requests';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { LoaderModal } from '@legacy/components/LoaderModal';
import { PageHeader } from '@legacy/components/PageHeader';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import {
  createRecordsRequest,
  getRecordByIdRequest,
  IGetRecordById,
  ISearchRecords,
  searchRecordsDebounced,
  updateRecordByIdRequest,
} from '@legacy/core/records/store/actions';
import {
  getRecordAssociationsRequest,
  getRecordAssociationWithNestedEntitiesRequest,
  IGetRecordAssociations,
  IGetRecordAssociationWithNestedEntities,
} from '@legacy/core/recordsAssociations/store/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
import { closeOrderBuilderDrawer } from '@legacy/core/userInterface/store/actions';
import { IUserInterfaceReducer } from '@legacy/core/userInterface/store/types';
import { IOrderCheckout, orderCheckoutRequest } from '@legacy/core/workflow/store/actions';
import { Col, Form, Result, Row, Spin } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AppointmentScheduler from './AppointmentScheduler';
import OrderBuilderAccountManager from './OrderBuilderAccountManager';
import OrderBuilderSendQuote from './OrderBuilderSendQuote';
import OrderDetails from './OrderDetails';
import OrderItemsList from './OrderItemsList';
import OrderSummary from './OrderSummary';
import ProductSelection from './ProductSelection';

import { getOrganizationName } from '@core/http/helpers';
import dayjs from 'dayjs';
import {
  RESET_ORDER_BUILDER_STATE,
  SET_ACCOUNT,
  SET_ADDRESS,
  SET_CONTACT,
  SET_DISCOUNT_LIST,
  SET_IS_APPOINTMENT_CONFIRMED,
  SET_LOADING_OFFERS,
  SET_LOADING_PRODUCTS,
  SET_OFFER_LIST,
  SET_OFFER_PRODUCT_LIST,
  SET_ORDER_ITEMS,
  SET_ORDER_SUMMARY,
  SET_PAYMENT_METHOD,
  SET_RECONTRACTING_QUOTE,
  SET_SELECTED_OFFER,
} from './store/constants';
import { OrderBuilderInitialState, orderBuilderReducer } from './store/reducer';
import './styles.scss';
import { initialOrderSummary, IOrderBuilderApplyRecontractQuoteDto } from './types';

type Props = RouteComponentProps<any> & {
  defaultAccount?: DbRecordEntityTransform;
  defaultLead?: DbRecordEntityTransform;
  history: any;
  getRecordById: any;
  searchRecords: any;
  getAssociations: Function;
  getNestedAssociations: Function;
  createRecord: Function;
  updateRecord: Function;
  alertMessage: Function;
  orderCheckout: (params: IOrderCheckout, cb: any) => void;
  closeDrawer: () => void;
  userInterfaceReducer: IUserInterfaceReducer;
};

const { OFFER, PRODUCT, CONTACT, PAYMENT_METHOD, DISCOUNT, ORDER, ADDRESS } =
  SchemaModuleEntityTypeEnums;

const { CRM_MODULE, ORDER_MODULE } = SchemaModuleTypeEnums;

export const orderBuilderContext = React.createContext<any>({});

const isNetomnia: boolean = getOrganizationName() === 'Netomnia';

const OrderBuilder: React.FC<Props> = (props: Props) => {
  const {
    userInterfaceReducer,
    searchRecords,
    closeDrawer,
    getAssociations,
    orderCheckout,
    alertMessage,
    defaultAccount,
    defaultLead,
  } = props;

  const [state, dispatch] = useReducer(orderBuilderReducer, OrderBuilderInitialState);
  const [accountSchema, setAccountSchema] = useState<SchemaEntity | undefined>(undefined);
  const [offerSchema, setOfferSchema] = useState<SchemaEntity | undefined>(undefined);
  const [contactSchema, setContactSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isSettingUpRecontracting, setIsSettingUpRecontracting] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { orderBuilderRecontractingId } = userInterfaceReducer;
  const isRecontracting: boolean = !!orderBuilderRecontractingId;

  // When schema shortlist updates, try to fetch all necessary schemas.
  useEffect(() => {
    getSchemas();
  }, []);

  // In standard order builder, when the order items change, recalculate the order summary.
  useEffect(() => {
    if (state.orderItems.length > 0 && !isRecontracting) {
      getStandardOrderSummary(state.orderItems, state.discount?.id);
    } else if (state.orderItems.length === 0 && !isRecontracting) {
      dispatch({ type: SET_ORDER_SUMMARY, payload: initialOrderSummary });
    }
  }, [state.orderItems, state.discount]);

  // In Recontracting mode, when the order items change, recalculate the order summary.
  useEffect(() => {
    if (
      state.orderItems.length > 0 &&
      state.contact &&
      state.address &&
      state.offer &&
      isRecontracting
    ) {
      getRecontractingOrderSummary(
        state.orderItems,
        state.contact.id,
        state.address.id,
        state.offer.id,
      );
    } else if (state.orderItems.length === 0 && isRecontracting) {
      dispatch({ type: SET_ORDER_SUMMARY, payload: initialOrderSummary });
    }
  }, [state.orderItems, state.contact, state.address, state.offer]);

  // If default account is passed, set it to the state and fetch associated contact / address
  useEffect(() => {
    if (defaultAccount && !state.account) {
      dispatch({ type: SET_ACCOUNT, payload: defaultAccount });

      const contactId = getProperty(defaultAccount, 'ContactId');
      const addressId = getProperty(defaultAccount, 'AddressId');

      // Fetch contact
      if (contactId) {
        httpGet(`${CRM_MODULE}/v1.0/db/${CONTACT}/${contactId}`).then((contact: any) => {
          if (contact?.data?.data) {
            dispatch({ type: SET_CONTACT, payload: contact?.data.data });
          }
        });
      }

      // Fetch Address
      if (addressId) {
        httpGet(`${CRM_MODULE}/v1.0/db/${ADDRESS}/${addressId}`).then((address: any) => {
          if (address?.data?.data) {
            dispatch({ type: SET_ADDRESS, payload: address?.data.data });
          }
        });
      }
    }
  }, [defaultAccount, accountSchema]);

  // In recontracting process, fetch recontracting quote and set it to the state
  useEffect(() => {
    if (isRecontracting && orderBuilderRecontractingId) {
      setIsSettingUpRecontracting(true);

      httpPost(`OrderModule/v1.0/orders/recontracting/get-quote`, {
        orderId: orderBuilderRecontractingId,
      })
        .then((res: any) => {
          if (res.data.data) {
            dispatch({ type: SET_RECONTRACTING_QUOTE, payload: res.data?.data });
          }
        })
        .catch((err: any) => {
          setIsSettingUpRecontracting(false);
          alertMessage({
            body: 'There was an error fetching recontracting quote. ' + JSON.stringify(err.message),
            type: 'error',
          });
          throw new Error(err.response?.data?.message ?? err.message);
        });
    }
  }, [orderBuilderRecontractingId]);

  // 1. In recontracting process, once the offer list and recontracting quote are set
  // try to find a matching offer from the offer list. Recontracting quote will have
  // nextOffer with an id. Find the offer with that id and set it to the state.
  //
  // 2. Recontracting quote will return nextProducts array. Parse through the array and
  // adjust the products to be added to the order. Set the products to the state.
  useEffect(() => {
    if (state.offerList.length > 0 && state.recontractingQuote) {
      const nextProducts = Object.assign([], state.recontractingQuote?.nextProducts);
      const matchingOffer = state.offerList.find(
        (offer: DbRecordEntityTransform) => offer.id === state.recontractingQuote?.nextOffer?.id,
      );

      // Find and set the matching offer
      if (matchingOffer) {
        dispatch({ type: SET_SELECTED_OFFER, payload: matchingOffer });
        form.setFieldValue('selectedOffer', matchingOffer.id);
      } else {
        setIsSettingUpRecontracting(false);
      }

      // Adjust and set the products from the recontracting quote
      if (nextProducts.length > 0) {
        let products: DbRecordEntityTransform[] = [];
        nextProducts.forEach((product: DbRecordEntityTransform) => {
          let adjustedProduct: DbRecordEntityTransform = Object.assign(product);
          adjustedProduct.properties.Quantity = 1;
          adjustedProduct.lockedRemoval = product.isRemovable;
          adjustedProduct.isAddedViaRecontracting = true;
          adjustedProduct.additionalParams = {
            offerId: state.recontractingQuote?.nextOffer?.id,
          };
          products.push(adjustedProduct);
        });

        dispatch({ type: SET_ORDER_ITEMS, payload: products });
        setIsSettingUpRecontracting(false);
      }
    }
  }, [state.offerList, state.recontractingQuote]);

  const getRecontractingOrderSummary = (
    products: DbRecordEntityTransform[],
    contactId: string,
    addressId: string,
    offerId: string,
  ) => {
    httpPost(`${ORDER_MODULE}/v1.0/orders/recontracting/get-order-summary`, {
      contactId,
      addressId,
      offerId,
      products: products.map((product: DbRecordEntityTransform) => ({
        id: product.id,
        properties: product.properties,
      })),
    }).then((response: any) => {
      if (response.data?.data) {
        dispatch({ type: SET_ORDER_SUMMARY, payload: response.data?.data });
      }
    });
  };

  const getStandardOrderSummary = (
    products: DbRecordEntityTransform[],
    discountId: DbRecordEntityTransform | undefined,
  ) => {
    const filteredProducts = products?.map((product: DbRecordEntityTransform) => ({
      recordId: product.id,
      relatedAssociationId:
        product.dbRecordAssociation && product.dbRecordAssociation.relatedAssociationId
          ? product.dbRecordAssociation.relatedAssociationId
          : null,
      properties: {
        Quantity: getProperty(product, 'Quantity'),
      },
    }));

    httpPost(`${ORDER_MODULE}/v1.0/orders/quote`, {
      discountId: discountId || null,
      products: filteredProducts,
    }).then((response: any) => {
      dispatch({ type: SET_ORDER_SUMMARY, payload: response.data?.data });
    });
  };

  const getSchemas = async () => {
    const offer_schema = await getOdinSchemaByEntity(CRM_MODULE, OFFER);
    const contact_schema = await getOdinSchemaByEntity(CRM_MODULE, CONTACT);

    setOfferSchema(offer_schema);
    setContactSchema(contact_schema);
  };

  //Once contact record and schema are available, fetch the payment method into the state
  useEffect(() => {
    if (contactSchema && state.contact && !state.paymentMethod) {
      getAssociations(
        {
          recordId: state.contact?.id,
          schema: contactSchema,
          entities: [PAYMENT_METHOD],
        },
        (res: any) => {
          // Get associated Payment method and use the first one for now
          const relatedPaymentMethod = getFirstRelation(res.results, PAYMENT_METHOD);
          if (relatedPaymentMethod) {
            dispatch({ type: SET_PAYMENT_METHOD, payload: relatedPaymentMethod });
          }
        },
      );
    }
  }, [contactSchema, state.contact]);

  // Once Offer schema and Address are available, get all offers into the reducer
  useEffect(() => {
    if (offerSchema && state.address) {
      // 13-Jan-2025 - Rule for filtering offers based on address classification
      //
      // If Address Classification = RESIDENTIAL, show only Offers with CustomerType = RESIDENTIAL
      // If Address Classification = BUSINESS, show only Offers with CustomerType = BUSINESS
      // Else, show all offers

      const AddressClassification = getProperty(state.address, 'Classification');

      let CustomerType: string = '*';
      if (AddressClassification === 'RESIDENTIAL') {
        CustomerType = 'RESIDENTIAL';
      } else if (AddressClassification === 'BUSINESS') {
        CustomerType = 'BUSINESS';
      }

      searchRecords(
        {
          schema: offerSchema,
          searchQuery: {
            terms: '*',
            sort: [
              {
                createdAt: {
                  order: 'desc',
                },
              },
            ],
            schemas: offerSchema?.id,
            boolean: {
              must: [
                {
                  query_string: {
                    fields: ['properties.CustomerType'],
                    query: CustomerType,
                    lenient: true,
                    default_operator: 'AND',
                  },
                },
              ],
            },
            pageable: {
              page: 1,
              size: 300,
            },
          },
        },
        (res: any) => {
          if (res.data?.data) {
            dispatch({ type: SET_OFFER_LIST, payload: res.data?.data });
            // console.log('%cdebug: Offers', 'color:aquamarine', res?.data.data);
          }
          dispatch({ type: SET_LOADING_OFFERS, payload: false });
        },
      );
    }
  }, [offerSchema, state.address]);

  // When offer is selected, fetch the list of offer products
  useEffect(() => {
    if (state.offer && offerSchema) {
      dispatch({ type: SET_LOADING_PRODUCTS, payload: true });
      getAssociations(
        {
          recordId: state.offer?.id,
          key: OFFER,
          schema: offerSchema,
          entities: [PRODUCT, DISCOUNT],
        },
        (res: any) => {
          dispatch({ type: SET_LOADING_PRODUCTS, payload: false });
          if (res && res.results?.[PRODUCT]?.dbRecords?.length > 0) {
            const products = res.results?.[PRODUCT]?.dbRecords;
            dispatch({ type: SET_OFFER_PRODUCT_LIST, payload: products });
            dispatch({ type: SET_LOADING_PRODUCTS, payload: false });
          }

          if (res && res.results?.[DISCOUNT]?.dbRecords?.length > 0) {
            const discounts = res.results?.[DISCOUNT]?.dbRecords;
            dispatch({ type: SET_DISCOUNT_LIST, payload: discounts });
          }
        },
      );
    }
  }, [state.offer]);

  const recontractOrder = async () => {
    if (validateOrder()) {
      dispatch({ type: 'SET_IS_CREATING_ORDER', payload: true });

      let recontractPayload: IOrderBuilderApplyRecontractQuoteDto = {};
      const orderId = userInterfaceReducer.orderBuilderRecontractingId;

      let products = state.orderItems.map((product: DbRecordEntityTransform) => ({
        id: product.id,
        properties: product.properties,
      }));

      recontractPayload = {
        orderId,
        billingStartDate: state.recontractingQuote?.billingStartDate,
        serviceStartDate: state.recontractingQuote?.serviceStartDate,
        offerId: state.offer?.id,
        products: products,
        customerPhonePorting: {
          AreaCode: state.phoneNum2,
          CountryCode: state.phoneNum1,
          SubscriberNumber: state.phoneNum3,
          AuthorizedLOA: state.LOAAccepted,
          // PortFromProvider: state.portFromProvider,
        },
        skuDeviceMap: state.recontractingQuote.skuDeviceMap || null,
        skuPriceChangeMap: state.recontractingQuote.skuPriceChangeMap || null,
      };

      console.log('debug: Recontracting apply payload', recontractPayload);

      httpPost(`${ORDER_MODULE}/v1.0/orders/recontracting/apply`, recontractPayload)
        .then((res: any) => {
          const newOrderId = res.data?.data?.newOrderId;

          console.log('%cdebug: Recontracting apply response', 'color:limegreen', res.data?.data);

          if (newOrderId) {
            dispatch({ type: 'SET_IS_CREATING_ORDER', payload: false });
            dispatch({ type: 'SET_IS_ORDER_CREATED', payload: true });
            dispatch({ type: 'SET_ORDER_ID', payload: newOrderId });
          } else {
            dispatch({ type: 'SET_IS_CREATING_ORDER', payload: false });
          }
        })
        .catch((err: any) => {
          dispatch({ type: 'SET_IS_CREATING_ORDER', payload: false });
          dispatch({ type: 'SET_IS_ORDER_CREATED', payload: false });
          alertMessage({
            body: 'Error recontracting order. Please try again.' + JSON.stringify(err.message),
            type: 'error',
          });
          throw new Error(err.response?.data?.message ?? err.message);
        });
    }
  };

  const createOrder = async () => {
    if (validateOrder()) {
      dispatch({ type: 'SET_IS_CREATING_ORDER', payload: true });

      let orderItems = [];
      orderItems = state.orderItems?.map((product: DbRecordEntityTransform) => ({
        recordId: product.id,
        relatedAssociationId:
          product.dbRecordAssociation && product.dbRecordAssociation.relatedAssociationId
            ? product.dbRecordAssociation.relatedAssociationId
            : null,
        properties: {
          Quantity: getProperty(product, 'Quantity'),
        },
      }));

      let checkoutBody: any = {
        orderStageKey:
          getProperty(state.address, 'SalesStatus') === 'ORDER'
            ? 'OrderStageSold'
            : 'OrderStagePreOrder',
        identityName: 'GOCARDLESS',
        paymentMethodId: state.paymentMethod?.id,
        offerId: state.offer?.id,
        discountCode: state.discount ? getProperty(state.discount, 'Code') : null,
        account: { id: state.account?.id || null },
        contactId: state.contact?.id,
        addressId: state.address?.id,
        leadId: defaultLead?.id || undefined,
        products: orderItems,
        customerPhonePorting:
          state.phoneNum1 || state.phoneNum2 || state.phoneNum3 || state.LOAAccepted
            ? {
                CountryCode: state.phoneNum1,
                AreaCode: state.phoneNum2,
                SubscriberNumber: state.phoneNum3,
                AuthorizedLOA: state.LOAAccepted,
              }
            : undefined,
        contactProperties: {
          ReferralEmail: null,
        },
        orderProperties: {
          Source: 'DESKTOP_APP',
        },
      };

      // For Netomnia orders, we add additional properties
      if (isNetomnia) {
        checkoutBody = {
          ...checkoutBody,
          orderProperties: {
            ...checkoutBody.orderProperties,
            Account: state.account?.id,
            Address: state.address?.id,
            CurrentSequenceNumber: 1,
            OrderType: 'NEW',
            PrimaryContact: state.contact?.id,
            RequestedCompletionDate: dayjs().format('YYYY-MM-DD'),
            ServiceId: uuidv4(),
            Tenant: state.account?.tenantAccount?.[0]?.name || null,
          },
        };
      }

      orderCheckout(checkoutBody, (response: any) => {
        if (response.orderId) {
          dispatch({ type: 'SET_IS_ORDER_CREATED', payload: true });
          dispatch({ type: 'SET_ORDER_ID', payload: response?.orderId });
        } else {
          dispatch({ type: 'SET_IS_CREATING_ORDER', payload: false });
        }
      });
    } else {
      return false;
    }
  };

  const isAddressInOrderStatus = (): boolean => {
    return getProperty(state.address, 'SalesStatus') === 'ORDER';
  };

  const validateOrder = () => {
    if ((state.phoneNum2?.length > 0 || state.phoneNum3?.length > 0) && !state.LOAAccepted) {
      const { alertMessage } = props;

      alertMessage({
        body: 'Please check the LOA switch to continue.',
        type: 'error',
      });

      if (state.phoneTransferPanelRef) {
        state.phoneTransferPanelRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }

      return false;
    } else {
      return true;
    }
  };

  const canCreateOrder = () => {
    if (isNetomnia) {
      return !!(
        state.orderItems.length > 0 &&
        !state.isOrderCreated &&
        state.contact &&
        state.address &&
        state.account &&
        isAddressInOrderStatus()
      );
    } else {
      return !!(
        state.orderItems.length > 0 &&
        !state.isOrderCreated &&
        state.contact &&
        state.address &&
        state.account &&
        state.paymentMethod &&
        isAddressInOrderStatus()
      );
    }
  };

  const renderTitle = () => {
    if (isRecontracting) {
      return 'Recontracting Order';
    } else {
      if (state.isOrderCreated && !state.isAppointmentConfirmed) {
        return 'Select Appointment';
      } else {
        return 'Order Builder';
      }
    }
  };

  const isAccountIncomplete = () => {
    return !state.account || !state.contact || !state.address;
  };

  const shouldShowAccountManager = () => {
    return !state.isOrderCreated && !state.isAppointmentConfirmed && isAccountIncomplete();
  };

  return (
    <orderBuilderContext.Provider value={{ state, dispatch }}>
      <Spin spinning={isSettingUpRecontracting} tip="Setting up recontracting process...">
        <Row gutter={12}>
          <Col span={24}>
            {/* Page Header */}
            <PageHeader
              title={renderTitle()}
              className="invoiceBuilderHeader"
              style={{ border: '1px solid #dddbda', background: 'white' }}
              ghost={false}
              extra={
                <>
                  {/* Send Quote Button */}
                  {!state.isOrderCreated && canCreateOrder() && <OrderBuilderSendQuote />}

                  {/* Create Order / Recontract Order Button */}
                  {!state.isOrderCreated && (
                    <Button
                      intent="primary"
                      disabled={!canCreateOrder() || state.isCreatingOrder}
                      onClick={() => (isRecontracting ? recontractOrder() : createOrder())}
                      loading={state.isCreatingOrder}
                    >
                      {isRecontracting ? 'Recontract Order' : 'Create Order'}
                    </Button>
                  )}
                </>
              }
            >
              {/*
                  If order is not created and appointment is not confirmed, show
                  the account Flow CTA
              */}
              {shouldShowAccountManager() ? (
                <OrderBuilderAccountManager defaultAccount={props.defaultAccount} />
              ) : (
                <></>
              )}

              {/*
                  If order is not created and appointment is not confirmed, but the records
                  are available, show the available records in the row
              */}
              {!state.isOrderCreated &&
                !state.isAppointmentConfirmed &&
                state.account &&
                state.contact &&
                state.address && (
                  <Row>
                    <Col span={24}>
                      <OrderDetails isNetomnia={isNetomnia} />
                    </Col>
                  </Row>
                )}

              {/*
                  If order is created and appointment is not yet selected, show the
                  weekly appointment calendar. Omit this step when recontracting.
              */}
              {state.isOrderCreated &&
                !state.isAppointmentConfirmed &&
                state.orderId &&
                !isRecontracting && (
                  <Row>
                    <Col span={24}>
                      <AppointmentScheduler
                        workOrderType="INSTALL"
                        orderId={state.orderId}
                        onSuccess={() => {
                          dispatch({ type: SET_IS_APPOINTMENT_CONFIRMED, payload: true });
                        }}
                      />
                    </Col>
                  </Row>
                )}

              {/*
                  If Order is recontracted, show success message.
              */}
              {state.isOrderCreated &&
                !state.isAppointmentConfirmed &&
                state.orderId &&
                isRecontracting && (
                  <Row>
                    <Col span={24}>
                      <Row style={{ marginTop: 10, marginBottom: 10 }} justify="center">
                        <Col xs={24} lg={10}>
                          <Result
                            style={{ padding: 10 }}
                            status="success"
                            title="Order Successfully Recontracted"
                            subTitle="Order is successfully recontracted. Click on the button below to navigate to the new order."
                            extra={[
                              <Link
                                to={`/${ORDER_MODULE}/${ORDER}/${state.orderId}`}
                                target="_blank"
                              >
                                <Button intent="primary" large>
                                  View Order
                                </Button>
                              </Link>,
                              <Button
                                intent="primary"
                                large
                                outlined
                                onClick={() => {
                                  dispatch({ type: RESET_ORDER_BUILDER_STATE, payload: true });
                                  closeDrawer();
                                }}
                              >
                                Close
                              </Button>,
                            ]}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

              {/*
                  If order is created and appointment is selected, show the
                  success message with links to newly created order.
              */}
              {state.isOrderCreated && state.isAppointmentConfirmed && (
                <Row style={{ marginBottom: 10 }} justify="center">
                  <Col xs={24} lg={10}>
                    <Result
                      style={{ padding: 10 }}
                      status="success"
                      title="Order Successfully Created"
                      subTitle="Order is successfully created. Click on the button below to navigate to the order."
                      extra={[
                        <Link to={`/${ORDER_MODULE}/${ORDER}/${state.orderId}`} target="_blank">
                          <Button intent="primary" large outlined>
                            View Order
                          </Button>
                        </Link>,
                        <Button intent="primary" large onClick={closeDrawer}>
                          Close
                        </Button>,
                      ]}
                    />
                  </Col>
                </Row>
              )}
            </PageHeader>
          </Col>

          {/*
              If order is not yet created, show the left pane with product selection,
              and the Order Items panel + Order Summary
          */}
          {!state.isOrderCreated && (
            <>
              <Col xs={24} lg={8} className="invoiceBuilderPane">
                <Section title="Offer & Product Selection" style={{ minHeight: '100%' }}>
                  <ProductSelection form={form} />
                </Section>
              </Col>
              <Col xs={24} lg={16} className="invoiceBuilderPane">
                <Row>
                  {/* Order Items */}
                  {!state.isOrderCreated ? (
                    <Col span={24}>
                      <Section title="Order Items" style={{ minHeight: 300 }}>
                        <OrderItemsList />
                      </Section>
                    </Col>
                  ) : (
                    <></>
                  )}

                  {/* Order Summary */}
                  <Col span={24} style={{ marginTop: state.isOrderCreated ? 0 : 15 }}>
                    <Section
                      title="Order Summary"
                      style={{ minHeight: state.isOrderCreated ? 300 : 'auto' }}
                    >
                      <OrderSummary />
                    </Section>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
        <LoaderModal visible={state.isCreatingOrder} loaderMessage="Generating Order..." />
      </Spin>
    </orderBuilderContext.Provider>
  );
};
const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
});

const mapDispatch = (dispatch: any) => ({
  orderCheckout: (params: IOrderCheckout, cb: any) => dispatch(orderCheckoutRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  createRecord: (params: any, cb: any) => dispatch(createRecordsRequest(params, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsDebounced(params, cb)),
  getRecordById: (payload: IGetRecordById, cb?: any) => dispatch(getRecordByIdRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getNestedAssociations: (params: IGetRecordAssociationWithNestedEntities, cb: any) =>
    dispatch(getRecordAssociationWithNestedEntitiesRequest(params, cb)),
  updateRecord: (params: any, cb: any) => dispatch(updateRecordByIdRequest(params, cb)),
  closeDrawer: () => dispatch(closeOrderBuilderDrawer()),
});

export default withRouter(connect(mapState, mapDispatch)(OrderBuilder));
