import { CaretDownFilled, CheckOutlined, SyncOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Alert, Button, Col, Divider, Dropdown, MenuProps, Row, Space, Tag } from 'antd';
import { DateTime } from 'luxon';
import { FC, useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { OrderNetworkDevicesContext } from '../index';
import CoreForm from '@legacy/core/records/components/Forms/CoreForm';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import {
  deleteRecordAssociationById,
  IDeleteRecordAssociation,
} from '@legacy/core/recordsAssociations/store/actions';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import { httpPost } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import DeviceListSection from '../shared/DeviceListSection';
import {
  SET_NETWORK_DRAWER_DEVICE_RECORD,
  SET_NETWORK_DRAWER_ORDER_ITEM_RECORD,
  SET_NETWORK_DRAWER_VISIBLE,
} from '../store/constants';

interface Props {
  routerRecord: DbRecordEntityTransform; // Router Device
  OIRecord: DbRecordEntityTransform; // OrderItem
  onUpdated?: () => void;
  schemaReducer: any;
  initializeForm: (params: any) => void;
  deleteAssociation: (params: IDeleteRecordAssociation, cb?: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  staticIpRecord?: DbRecordEntityTransform; // Static IP OrderItem
  disabled?: boolean;
}

const uuid = uuidv4();

const { SERVICE_MODULE, ORDER_MODULE } = SchemaModuleTypeEnums;
const { CUSTOMER_DEVICE_ROUTER, ORDER_ITEM } = SchemaModuleEntityTypeEnums;

const ActivatedRouter: FC<Props> = (props: Props) => {
  const {
    routerRecord,
    OIRecord,
    onUpdated,
    schemaReducer,
    initializeForm,
    deleteAssociation,
    alertMessage,
    staticIpRecord,
    disabled,
  } = props;
  const [isRunningCheck, setIsRunningCheck] = useState<boolean>(false);
  const [isRestarting, setIsRestarting] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);
  const { state, dispatch } = useContext(OrderNetworkDevicesContext);

  const runCheck = async () => {
    setIsRunningCheck(true);
    await httpPost(`ServiceModule/v2.0/network/router/device/${routerRecord.id}/check`, {})
      .then((res) => {
        setIsRunningCheck(false);
        onUpdated && onUpdated();
        console.log('debug: speed test', res);
      })
      .catch((err: any) => {
        setError('There was an error running a check.');
        console.log('debug: check failed!');
        setIsRunningCheck(false);
      });
  };

  const restartRouter = () => {
    setError(undefined);
    setIsRestarting(true);
    httpPost(`ServiceModule/v2.0/network/router/device/${routerRecord.id}/restart`, {})
      .then((res: any) => {
        alertMessage({
          body: `Device is being restarted, this may take some time.`,
          type: 'success',
        });
        setIsRestarting(false);
      })
      .catch((err: any) => {
        setIsRestarting(false);
        const error = err.response ? err.response.data : undefined;
        setError(error?.message || 'Could not restart device.');
        console.log('debug: rebooting err', err);
      });
  };

  const initializeUpdateForm = () => {
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SERVICE_MODULE,
      CUSTOMER_DEVICE_ROUTER,
    );
    if (schema) {
      initializeForm({
        formUUID: uuid,
        title: `Update ${schema.description}`,
        selected: routerRecord,
        showFormModal: true,
        isCreateReq: false,
        isUpdateReq: true,
        schema: schema,
        sections: [{ name: schema.name, schema: schema }],
      });
    }
  };

  const removeRouterFromOrderItem = () => {
    const OISchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      ORDER_MODULE,
      ORDER_ITEM,
    );
    const routerSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SERVICE_MODULE,
      CUSTOMER_DEVICE_ROUTER,
    );

    if (OISchema && routerSchema) {
      const schemaAssociation = OISchema?.associations.find(
        (assoc: any) =>
          assoc.childSchemaId === routerSchema?.id || assoc.parentSchemaId === routerSchema.id,
      );

      console.log('debug: schema assoc', schemaAssociation);

      if (schemaAssociation) {
        deleteAssociation(
          {
            schema: OISchema,
            schemaAssociation: schemaAssociation,
            dbRecordAssociationId: routerRecord?.dbRecordAssociation?.id || '',
          },
          () => {
            onUpdated && onUpdated();
          },
        );
      }
    }
  };

  const areActionsDisabled = () => {
    return disabled || getProperty(routerRecord, 'LastAction') === 'CHECK_REQUEST';
  };

  const getItems = () => {
    const items: MenuProps['items'] = [
      {
        key: '1',
        label: 'Edit',
        onClick: initializeUpdateForm,
        icon: <i className="bi bi-pencil-square" />,
      },
      {
        key: '2',
        label: 'Remove',
        danger: true,
        onClick: removeRouterFromOrderItem,
        icon: <i className="bi bi-trash3" />,
      },
    ];

    if (['ARRIS', 'SAGEMCOM'].includes(getProperty(routerRecord, 'Model'))) {
      items.push({
        key: '3',
        label: 'Restart',
        onClick: restartRouter,
        icon: <i className="bi bi-arrow-clockwise" />,
      });
    }

    return items;
  };

  const openNetworkDrawer = () => {
    dispatch({ type: SET_NETWORK_DRAWER_VISIBLE, payload: true });
    dispatch({ type: SET_NETWORK_DRAWER_DEVICE_RECORD, payload: routerRecord });
    dispatch({ type: SET_NETWORK_DRAWER_ORDER_ITEM_RECORD, payload: OIRecord });
  };

  const detailsSection = () => {
    let tag = <Tag>STATE UNKNOWN</Tag>;

    const model = getProperty(routerRecord, 'Model');
    const serialNumber = getProperty(routerRecord, 'SerialNumber');
    const online = getProperty(routerRecord, 'Online');
    const upSpeed = getProperty(routerRecord, 'SpeedUp');
    const downSpeed = getProperty(routerRecord, 'SpeedDown');
    const macAddress = getProperty(routerRecord, 'MacAddress');
    const lastAction = getProperty(routerRecord, 'LastAction');
    const ipAddress = getProperty(routerRecord, 'IpAddress');
    const isStatic = getProperty(routerRecord, 'IpAddressStatic');

    if (ipAddress || macAddress) {
      tag = <Tag color={'yellow'}>CONFIGURED</Tag>;
    }

    if (online === 'true') {
      tag = (
        <Tag icon={<CheckOutlined />} color={'green'}>
          ONLINE
        </Tag>
      );
    } else if (online === 'false') {
      tag = <Tag color={'red'}>OFFLINE</Tag>;
    }

    if (lastAction === 'CHECK_REQUEST') {
      tag = (
        <Tag icon={<SyncOutlined spin />} color={'orange'}>
          CHECKING
        </Tag>
      );
    }

    const details = [
      {
        label: 'Model',
        value: model,
      },
      {
        label: 'Serial #',
        value: serialNumber,
      },
      {
        label: 'Online',
        value: online === 'true' ? 'Online' : online === 'false' ? 'Offline' : undefined,
        validValues: ['Online'],
      },
      {
        label: 'Upload',
        value: upSpeed ? `${Number(upSpeed).toFixed(0)} Mbps` : undefined,
      },
      {
        label: 'Download',
        value: downSpeed ? `${Number(downSpeed).toFixed(0)} Mbps` : undefined,
      },
      {
        label: 'IP Address',
        value: ipAddress,
        tag:
          staticIpRecord && ipAddress && isStatic === 'true'
            ? { text: 'STATIC', color: 'blue' }
            : staticIpRecord && ipAddress && isStatic === 'false'
              ? { text: 'NOT STATIC', color: 'red' }
              : undefined,
      },
      {
        label: 'MAC Address',
        value: macAddress,
      },
    ];

    return <DeviceListSection name="Router" details={details} tags={[tag]} />;
  };

  const metaSection = () => {
    const lastAction = getProperty(routerRecord, 'LastAction');
    const lastActionDate = getProperty(routerRecord, 'LastActionDate');
    const lastCheckDate = getProperty(routerRecord, 'NetworkStatusLastChecked');

    const details = [
      {
        label: 'Last Action',
        value:
          lastAction && lastActionDate
            ? `${lastAction} at ${DateTime.fromISO(lastActionDate).toFormat('d/M/yyyy h:mm a')}`
            : undefined,
      },
      {
        label: 'Last Check Status at',
        value: lastCheckDate
          ? DateTime.fromISO(lastCheckDate).toFormat('d/M/yyyy h:mm a')
          : undefined,
      },
    ];

    return <DeviceListSection details={details} columns={1} layout={'horizontal'} />;
  };

  return (
    <div
      style={{
        borderRadius: 2,
        background: '#f5f5f5',
        border: '1px solid #e2e2e2',
        padding: '15px 15px',
      }}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <ModuleEntityIcon moduleName={SERVICE_MODULE} entityName={CUSTOMER_DEVICE_ROUTER} />
          <span style={{ fontWeight: 600 }}>Router Device</span>
        </Col>
        <Col style={{ marginTop: isMobile ? 15 : 0 }}>
          {/* Dropdown Button - Manage */}
          <Dropdown menu={{ items: getItems() }} trigger={['click']} disabled={disabled}>
            <Button
              type="primary"
              size="small"
              ghost
              style={{ marginRight: 10 }}
              loading={isRestarting}
              disabled={disabled}
            >
              <Space>
                Manage
                <CaretDownFilled />
              </Space>
            </Button>
          </Dropdown>

          {/* Button - Show Details */}
          <Button
            onClick={openNetworkDrawer}
            disabled={
              disabled ||
              !['EERO', 'ARRIS', 'SAGEMCOM'].includes(getProperty(routerRecord, 'Model'))
            }
            size="small"
            style={{ marginRight: 10 }}
            type="primary"
            ghost
          >
            Show Details
          </Button>
          <Button
            icon={<i className="bi bi-check2-circle" />}
            type="primary"
            size="small"
            disabled={
              areActionsDisabled() ||
              isRestarting ||
              isRunningCheck ||
              state.runningBulkRouterCheck ||
              !['EERO', 'ARRIS', 'SAGEMCOM'].includes(getProperty(routerRecord, 'Model'))
            }
            onClick={runCheck}
            loading={
              getProperty(routerRecord, 'LastAction') === 'CHECK_REQUEST' ||
              isRunningCheck ||
              state.runningBulkONTCheck
            }
          >
            Run Check
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider style={{ marginTop: 15, marginBottom: 10, borderColor: '#e2e2e2' }} />
        </Col>

        <Col span={24}>
          {!['EERO', 'ARRIS', 'SAGEMCOM'].includes(getProperty(routerRecord, 'Model')) && (
            <Alert
              style={{ marginBottom: 12 }}
              message={`Speed test for ${getProperty(
                routerRecord,
                'Model',
              )} routers is not supported yet.`}
            />
          )}
        </Col>
        <Col span={24}>
          {detailsSection()}

          <Divider style={{ marginTop: 10, marginBottom: 15 }} />

          {metaSection()}
        </Col>
      </Row>

      {/* Error Message */}
      {error && (
        <Row style={{ marginTop: 15 }}>
          <Col span={24}>
            <Alert
              showIcon
              type="error"
              message={error}
              closable
              onClose={() => setError(undefined)}
            />
          </Col>
        </Row>
      )}

      {/* Form */}
      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={(params: { event: string; res: any }) => {
          onUpdated && onUpdated();
        }}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any, ownProps: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  deleteAssociation: (params: IDeleteRecordAssociation, cb: () => {}) =>
    dispatch(deleteRecordAssociationById(params, cb)),
});

export default connect(mapState, mapDispatch)(ActivatedRouter);
