import history from '@core/helpers/browserHistory';
import { changeToCapitalCase } from '@core/helpers/dataTransformationHelpers';
import { canUserDeleteRecord, canUserUpdateRecord, hasAnyRoles } from '@core/helpers/rbacRules';
import { checkRecordIsLocked, getBrowserPath } from '@core/helpers/recordHelpers';
import {
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '@core/helpers/schemaHelpers';
import { httpPost } from '@core/http/requests';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getAllRelations } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { Button, Popconfirm, Row, Spin, Typography } from 'antd';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { PageHeader } from '../../../../components/PageHeader';
import { initailizeCancelAppointmentModal } from '../../../appointments/store/actions';
import { listUsers } from '../../../identity/store/actions';
import { displayMessage } from '../../../messages/store/reducers';
import { getPipelinesByModuleAndEntity } from '../../../pipelines/store/actions';
import {
  getRecordAssociationByIdRequest,
  getRecordAssociationsRequest,
  getRecordAssociationWithNestedEntitiesRequest,
  IGetRecordAssociationById,
  IGetRecordAssociations,
  IGetRecordAssociationWithNestedEntities,
} from '../../../recordsAssociations/store/actions';
import { DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST } from '../../../recordsAssociations/store/constants';
import { IRecordAssociationsReducer } from '../../../recordsAssociations/store/reducer';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import {
  createRecordsRequest,
  deleteRecordByIdRequest,
  getRecordByIdRequest,
  IGetRecordById,
} from '../../store/actions';
import { CREATE_DB_RECORD_REQUEST, UPDATE_DB_RECORD_BY_ID_REQUEST } from '../../store/constants';
import { IRecordReducer } from '../../store/reducer';
import CoreForm from '../Forms/CoreForm';
import { initializeRecordForm } from '../Forms/store/actions';

const { NOTE } = SchemaModuleEntityTypeEnums;

interface IProps {
  userReducer: any;
  record: DbRecordEntityTransform;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  initializeForm: any;
  title?: string;
  descriptions?: any[];
  extraActions?: any[];
  extraContent?: any;
  children?: any;
  disableBreadcrumbs?: boolean;
  deleteRecord: any;
  getRecord: any;
  getRelatedRecordById: any;
  getUsers: any;
  getPipelines: any;
  getAssociations: any;
  hasColumnMappings?: boolean;
  disableDelete?: boolean;
  disableClone?: boolean;
  disableEdit?: boolean;
  visibleProperties?: string[];
  initializeCancelAppointment: any;
  alertMessage: any;
  createRecord: (params: any, cb: any) => any;
  getAssociationWithNestedEntities: (
    params: IGetRecordAssociationWithNestedEntities,
    cb: any,
  ) => any;
}

interface IState {
  showStatusChangeModal?: boolean;
  statusId?: number | null;
  initialDisabledButtonState: boolean;
}

const uuid = uuidv4();

class RecordPageHeader extends React.Component<IProps, IState> {
  state = {
    initialDisabledButtonState: true,
  };

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData() {
    const { record, schemaReducer, getAssociationWithNestedEntities } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      'BillingModule',
      'BillingRequest',
    );
    if (record) {
      getAssociationWithNestedEntities(
        {
          recordId: record.id,
          key: 'Activity',
          schema: schema as SchemaEntity,
          entity: 'Activity',
          nestedEntities: [],
          withLinks: false,
        },
        () => {
          this.setState({
            initialDisabledButtonState: false,
          });
        },
      );
    }
  }

  async initializeUpdateForm() {
    const {
      record,
      schemaReducer,
      initializeForm,
      getUsers,
      hasColumnMappings,
      getPipelines,
      visibleProperties,
    } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

    if (schema) {
      const parentRecordEntityName = record.entity?.split(':')[1];

      getUsers();
      getPipelines({ schema: schema });

      initializeForm({
        formUUID: uuid,
        title: hasColumnMappings
          ? `Update Linked Record (${parentRecordEntityName} → ${schema.entityName})`
          : `Update ${schema.entityName}`,
        hasColumnMappings,
        visibleFieldOverride: visibleProperties,
        showFormModal: true,
        isUpdateReq: true,
        schema: schema,
        recordType: record.type,
        selected: record,
        sections: [{ name: schema.name, schema: schema }],
      });
    }
  }

  setFinalNoticeButtonInitialDisabledState() {
    const { record, recordAssociationReducer } = this.props;

    if (this.state?.initialDisabledButtonState) return true;

    if (record) {
      const createdAt = new Date(record.createdAt as Date).getTime();
      const now = new Date().getTime();
      const fiveDays = 5 * 24 * 60 * 60;
      const associationKey = `${record?.id}_Activity`;
      const associationObj: any = recordAssociationReducer.shortList?.[associationKey];
      const activityRocords = getAllRelations(associationObj, 'Activity');

      if (activityRocords) {
        return true;
      } else {
        if (
          record.properties.Status !== 'FULFILLED' &&
          record.properties.Status !== 'CANCELLED' &&
          record.properties.Status !== 'READY_TO_FULFIL' &&
          now >= createdAt + fiveDays
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  render() {
    const {
      userReducer,
      record,
      schemaReducer,
      recordReducer,
      extraActions,
      hasColumnMappings,
      disableDelete,
      disableClone,
      disableEdit,
    } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    return (
      <Fragment>
        <CoreForm
          type="MODAL"
          formUUID={uuid}
          onSubmitEvent={(params: { event: string; results: any }) => this.handleFormSubmit(params)}
        />

        <Spin tip="Loading..." spinning={recordReducer.isRequesting}>
          <PageHeader
            ghost={false}
            className="page-header"
            onBack={() => history.goBack()}
            title={changeToCapitalCase(schema?.entityName)}
            subTitle={record?.recordNumber}
            extra={[
              <>
                {schema?.entityName === 'ServiceAppointment' ? (
                  <Button
                    key="2"
                    ghost
                    danger
                    disabled={!canUserDeleteRecord(userReducer, schema, record)}
                    onClick={() => this.initializeCancelAppointment()}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Popconfirm
                    title="Are you sure you want to delete this record?"
                    disabled={
                      disableDelete ||
                      !canUserDeleteRecord(userReducer, schema, record) ||
                      // ODN-1640 prevent deleting if record is locked
                      (checkRecordIsLocked(record) &&
                        !hasAnyRoles(userReducer, 'system.admin', 'BillingModuleAdmin'))
                    }
                    onConfirm={() => this.deleteRecord()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      disabled={
                        disableDelete ||
                        !canUserDeleteRecord(userReducer, schema, record) ||
                        // ODN-1640 prevent deleting if record is locked
                        (checkRecordIsLocked(record) &&
                          !hasAnyRoles(userReducer, 'system.admin', 'BillingModuleAdmin')) ||
                        record?.entity === 'SchemaModule:Activity'
                      }
                      key="2"
                      ghost
                      danger
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                )}
                {schema?.entityName === 'BillingRequest' && (
                  <Popconfirm
                    title="Are you sure you want to send the Final Notice email?"
                    onConfirm={() => this.sendFinalNotice()}
                    okText="Yes"
                    cancelText="No"
                    disabled={this.setFinalNoticeButtonInitialDisabledState()}
                  >
                    <Button
                      ghost
                      key="1"
                      type="primary"
                      disabled={this.setFinalNoticeButtonInitialDisabledState()}
                    >
                      Send Final Notice
                    </Button>
                  </Popconfirm>
                )}
              </>,
              <Button
                key="1"
                type="primary"
                ghost
                disabled={
                  !!record?.dbRecordAssociation?.relatedAssociationId ||
                  disableEdit ||
                  !canUserUpdateRecord(userReducer, schema, record) ||
                  // ODN-1640 prevent editing if record is locked
                  (checkRecordIsLocked(record) &&
                    !hasAnyRoles(userReducer, 'system.admin', 'BillingModuleAdmin'))
                }
                onClick={() => this.initializeUpdateForm()}
              >
                Edit
              </Button>,
              extraActions,
            ]}
          >
            <Row>
              <Typography.Title level={4}>{record?.title}</Typography.Title>
            </Row>

            {this.props.children}
          </PageHeader>
        </Spin>
      </Fragment>
    );
  }

  private deleteRecord() {
    const { record, schemaReducer, deleteRecord } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    if (schema) {
      deleteRecord(
        {
          schema: schema,
          recordId: !!record ? record.id : null,
        },
        () => {
          history.push(`/${schema.moduleName}/${schema.entityName}`);
        },
      );
    }
  }

  private async sendFinalNotice() {
    const { record, alertMessage } = this.props;
    const body = {
      billingRequestId: record.id,
    };
    this.setState({
      initialDisabledButtonState: true,
    });
    await httpPost(`BillingModule/v1.0/billing-requests/send-final-notice`, body)
      .then(() => {
        alertMessage({ body: 'Final notice sent!', type: 'success' });
      })
      .catch((err) => {
        const error = err.response ? err.response.data : undefined;
        alertMessage({
          body: (error && error.message) || 'Final notice not sent!',
          type: 'error',
        });
      });
  }

  private handleFormSubmit(params: { event: string; results: any }) {
    const { getRecord, getRelatedRecordById, record, schemaReducer } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    switch (params.event) {
      case DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST:
        getRelatedRecordById({
          schema: schema,
          recordId: record.id,
          dbRecordAssociationId: record?.dbRecordAssociation?.id,
        });
        break;
      case UPDATE_DB_RECORD_BY_ID_REQUEST:
        getRecord({ schema, recordId: params.results.id });
        break;
      case CREATE_DB_RECORD_REQUEST:
        history.push(`${getBrowserPath(params.results)}`);
        break;
    }
  }

  private initializeCancelAppointment() {
    const { initializeCancelAppointment, record } = this.props;
    initializeCancelAppointment({
      cancelModalVisible: true,
      cancelRelatedRecord: record,
      schemaType: 'SA_CANCEL',
    });
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  getUsers: (cb: any) => dispatch(listUsers(cb)),
  getPipelines: (params: { schema: SchemaEntity }) =>
    dispatch(getPipelinesByModuleAndEntity(params)),
  getRelatedRecordById: (payload: IGetRecordAssociationById) =>
    dispatch(getRecordAssociationByIdRequest(payload)),
  getRecord: (payload: IGetRecordById) => dispatch(getRecordByIdRequest(payload)),
  deleteRecord: (payload: any, cb: any) => dispatch(deleteRecordByIdRequest(payload, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getAssociations: (params: IGetRecordAssociations, db: any) =>
    dispatch(getRecordAssociationsRequest(params, db)),
  initializeCancelAppointment: (params: any) => dispatch(initailizeCancelAppointmentModal(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  createRecord: (params: any, cb: any) => dispatch(createRecordsRequest(params, cb)),
  getAssociationWithNestedEntities: (params: IGetRecordAssociationWithNestedEntities, cb: any) =>
    dispatch(getRecordAssociationWithNestedEntitiesRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(RecordPageHeader);
