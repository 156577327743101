import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { httpPut } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getErrorMessage } from '../../../../utils/errors';

interface Props {
  entity: 'users' | 'onboarding-templates';
  sourceRecord: any;
  defaultSalesChannel: string;
  onUpdate?: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ISalesChannel {
  name: string;
  id: string;
  disabled: boolean;
}

const SalesChannels = [
  'Business Sales',
  'External Field Sales',
  'Internal Field Sales',
  'National Account Manager',
  'Nutun Telesales',
  'Lead Gen',
  'Telesales',
];

const UpdateSalesChannelSelect: FC<Props> = (props: Props) => {
  const { entity, defaultSalesChannel, sourceRecord, alertMessage, onUpdate } = props;
  const [allSalesChannels, setAllSalesChannels] = useState<ISalesChannel[]>([]);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState<ISalesChannel | undefined>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    let salesChannels = SalesChannels.map((channel) => ({
      name: channel,
      id: channel,
      disabled: false,
    }));
    setAllSalesChannels(salesChannels);
  }, []);

  useEffect(() => {
    if (defaultSalesChannel && defaultSalesChannel.length > 0) {
      setSelectedSalesChannel({
        id: defaultSalesChannel,
        name: defaultSalesChannel,
        disabled: false,
      });
    } else {
      setSelectedSalesChannel(undefined);
    }
  }, [defaultSalesChannel, sourceRecord]);

  // Update user if different one is selected
  const handleItemSelect = (item: any) => {
    if (item.id !== defaultSalesChannel) {
      updateEntityWithSalesChannel(item);
    }
  };

  const updateEntityWithSalesChannel = async (selectedChannel: ISalesChannel) => {
    setIsUpdating(true);

    let version = entity === 'users' ? 'v1.0' : 'v2.0';

    try {
      await httpPut(`IdentityModule/${version}/${entity}/${sourceRecord.id}`, {
        salesChannel: selectedChannel.id === 'no-channel' ? null : selectedChannel.id,
      });

      if (selectedChannel.id === 'no-channel') {
        setSelectedSalesChannel(undefined);
      } else {
        setSelectedSalesChannel(selectedChannel);
      }

      setIsUpdating(false);
      alertMessage({
        body: `Sales Channel updated`,
        type: 'success',
      });
    } catch (error: any) {
      setIsUpdating(false);
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update Sales Channel. ' + message,
        type: 'error',
      });
    }
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e);
  };

  const renderUser: ItemRenderer<ISalesChannel> = (
    salesChannel,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else if (salesChannel.id === 'search-info') {
      return (
        <MenuItem
          disabled={true}
          key="load-more"
          roleStructure="menuitem"
          text="Use Filter to find more sales channels..."
        />
      );
    } else
      return (
        <MenuItem
          active={salesChannel.id === selectedSalesChannel?.id}
          disabled={modifiers.disabled}
          key={salesChannel.id}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={salesChannel.name}
        />
      );
  };

  let SALES_CHANNELS: ISalesChannel[] = allSalesChannels.map(
    (sc: ISalesChannel, index: number) => ({
      id: sc.id,
      name: sc.name,
      disabled: false,
    }),
  );

  // Filter by search query
  if (searchQuery.length > 0) {
    SALES_CHANNELS = SALES_CHANNELS.filter((user) => {
      return user.name?.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  SALES_CHANNELS = SALES_CHANNELS.slice(0, 100);

  // Append the Search information if there are more than 100 items
  if (SALES_CHANNELS.length === 100) {
    SALES_CHANNELS.push({
      id: 'search-info',
      name: 'search-info',
      disabled: true,
    });
  }

  // Append "No Channel" option to the top of the list when the user is not searching
  if (searchQuery.length === 0) {
    SALES_CHANNELS.unshift({
      id: 'no-channel',
      name: '(No Channel)',
      disabled: false,
    });
  }

  return (
    <Select<ISalesChannel>
      items={SALES_CHANNELS}
      disabled={allSalesChannels.length === 0}
      itemRenderer={renderUser}
      noResults={<MenuItem disabled={true} text="No results" roleStructure="menuitem" />}
      onItemSelect={handleItemSelect}
      query={searchQuery}
      onQueryChange={(e: any) => handleQueryChange(e)}
    >
      <Button
        icon={selectedSalesChannel ? 'inherited-group' : null}
        alignText="left"
        disabled={allSalesChannels.length === 0 || isUpdating}
        text={selectedSalesChannel?.name || 'Select Sales Channel'}
        rightIcon="caret-down"
        fill
      />
    </Select>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(UpdateSalesChannelSelect);
